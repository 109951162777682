<script setup lang="ts">
import { computed } from 'vue';
import { differenceInYears } from 'date-fns';
import { MapPin, Calendar, Dumbbell, Target } from 'lucide-vue-next';
import type { Trainee } from '@/types/extended';

const props = defineProps<{
  trainee: Trainee;
}>();

function getAge(birthdate: string | null) {
  return birthdate ? differenceInYears(new Date(), new Date(birthdate)) : null;
}

const traineeInfo = computed(() => ({
  country: props.trainee.humanCountry,
  age: getAge(props.trainee.birthdate),
  equipment: props.trainee.equipment,
  shortTermGoal: props.trainee.shortTermGoal,
  longTermGoal: props.trainee.longTermGoal,
}));
</script>

<template>
  <div class="flex flex-wrap gap-2 text-sm">
    <div
      v-if="traineeInfo.country"
      class="flex items-center rounded-lg bg-blue-100 p-2 text-blue-950"
    >
      <MapPin class="mr-2 size-5" />
      <span>{{ traineeInfo.country }}</span>
    </div>
    <div
      v-if="traineeInfo.age"
      class="flex items-center rounded-lg bg-blue-100 p-2 text-blue-950"
    >
      <Calendar class="mr-2 size-5" />
      <span>{{ traineeInfo.age }} años</span>
    </div>
    <div
      v-if="traineeInfo.equipment"
      class="flex items-center rounded-lg bg-blue-100 p-2 text-blue-950"
    >
      <Dumbbell class="mr-2 size-5" />
      <span>{{ traineeInfo.equipment }}</span>
    </div>
    <div
      v-if="traineeInfo.longTermGoal"
      class="flex w-fit items-center rounded-lg bg-blue-100 p-2 text-blue-950"
    >
      <Target class="mr-2 size-5" />
      <span>Objetivo a largo plazo: {{ traineeInfo.longTermGoal }}</span>
    </div>
    <div
      v-if="traineeInfo.shortTermGoal"
      class="flex w-fit items-center rounded-lg bg-blue-100 p-2 text-blue-950"
    >
      <Target class="mr-2 size-5" />
      <span>Objetivo a corto plazo: {{ traineeInfo.shortTermGoal }}</span>
    </div>
  </div>
</template>
