<script setup lang="ts">
import { computed } from 'vue';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import type { WorkoutPhase } from '@/types/extended';
import type { ExerciseExecution, RoutineExerciseSet } from '@/types';

type ExerciseData = {
  name: string;
  sets: RoutineExerciseSet[];
  exerciseExecutions: ExerciseExecution[] | undefined;
};

type PhaseData = {
  id: number;
  name: string;
  exercises: ExerciseData[];
};

const props = defineProps<{
  phaseData: PhaseData;
  workoutPhase?: WorkoutPhase;
}>();

const phaseRowspan = computed(() =>
  props.phaseData.exercises.reduce(
    (total: number, exercise: ExerciseData) => total + (exercise.exerciseExecutions?.length || 1),
    0,
  ),
);

function formatDate(date: Date) {
  return format(date, 'dd/MM', { locale: es });
}

const workoutPhaseId = computed(() => props.workoutPhase?.id);
</script>

<template>
  <template
    v-for="(exercise, exerciseIndex) in props.phaseData.exercises"
    :key="`exercise-${exercise.routinePhaseExercise.id}`"
  >
    <tr v-if="!exercise.exerciseExecutions || exercise.exerciseExecutions.length === 0">
      <td
        v-if="exerciseIndex === 0"
        :rowspan="phaseRowspan"
        class="border-b border-r px-3 py-2 align-top font-medium"
      >
        {{ props.phaseData.name }}
      </td>
      <td class="border-b border-r px-3 py-2 align-top font-medium">
        {{ exercise.name }}
      </td>
      <td
        colspan="8"
        class="border-b px-3 py-2 text-center italic text-gray-500"
      >
        No se calcularon los datos de ejecución de este ejercicio.
      </td>
    </tr>
    <template v-else>
      <tr
        v-for="(execution, executionIndex) in exercise.exerciseExecutions"
        :key="`execution-${execution.id}`"
        class="border-b last:border-b-0"
      >
        <td
          v-if="executionIndex === 0 && exerciseIndex === 0"
          :rowspan="phaseRowspan"
          class="border-b border-r px-3 py-2 align-top font-medium"
        >
          {{ props.phaseData.name }}
        </td>
        <td
          v-if="executionIndex === 0"
          :rowspan="exercise.exerciseExecutions?.length"
          class="border-b border-r px-3 py-2 align-top font-medium"
        >
          {{ exercise.name }}
        </td>
        <td
          class="border-b border-r px-3 py-2"
          :class="{ 'border-gray-300 bg-blue-100': execution.workoutPhaseId === workoutPhaseId }"
        >
          {{ formatDate(new Date(execution.executionDate)) }}
        </td>
        <td
          class="border-b border-r px-3 py-2"
          :class="{ 'border-gray-300 bg-blue-100': execution.workoutPhaseId === workoutPhaseId }"
        >
          {{ execution.estimatedOneRepetitionMax?.toFixed(1) }} kg
        </td>
        <td
          class="border-b border-r px-3 py-2"
          :class="{ 'border-gray-300 bg-blue-100': execution.workoutPhaseId === workoutPhaseId }"
        >
          {{ execution.totalRepetitions }}
        </td>
        <td
          class="border-b border-r px-3 py-2"
          :class="{ 'border-gray-300 bg-blue-100': execution.workoutPhaseId === workoutPhaseId }"
        >
          {{ execution.sets }}
        </td>
        <td
          class="border-b border-r px-3 py-2"
          :class="{ 'border-gray-300 bg-blue-100': execution.workoutPhaseId === workoutPhaseId }"
        >
          {{ execution.maxWeight?.toFixed(1) }} kg
        </td>
        <td
          class="whitespace-nowrap border-b border-r px-3 py-2"
          :class="{ 'border-gray-300 bg-blue-100': execution.workoutPhaseId === workoutPhaseId }"
        >
          {{ execution.totalVolume?.toFixed(0) }} kg
        </td>
        <td
          class="border-b border-r px-3 py-2"
          :class="{ 'border-gray-300 bg-blue-100': execution.workoutPhaseId === workoutPhaseId }"
        >
          {{ execution.restDuration }}s
        </td>
        <td
          class="border-b border-r px-3 py-2"
          :class="{ 'border-gray-300 bg-blue-100': execution.workoutPhaseId === workoutPhaseId }"
        >
          {{ execution.averageEffort ? `${execution.averageEffort?.toFixed(0)}%` : '-' }}
        </td>
      </tr>
    </template>
  </template>
</template>
