<script setup lang="ts">
import { ref, computed } from 'vue';
import { AxiosError } from 'axios';
import { useMutation, useQuery } from '@tanstack/vue-query';
import { addDays, format } from 'date-fns';
import { daysInWeek } from 'date-fns/constants';
import { routinesApi, workoutPlansApi } from '@/api';
import type { RoutineAttributes } from '@/types';
import type { Routine } from '@/types/extended';
import { BaseButton, BaseInput, BaseLabel, BaseModal, BaseCombobox, BaseCheckbox } from '.';

const props = defineProps<{
  routine: Routine;
  isOpen: boolean,
}>();

const emit = defineEmits<{
  close: [isSuccess: boolean],
}>();

const { data: workoutPlanOptions } = useQuery({
  queryKey: ['workoutPlans'],
  queryFn: () => workoutPlansApi.getAll(),
  select: (data) => data.sort((a, b) => a.name.localeCompare(b.name)),
  initialData: [],
  enabled: () => props.isOpen,
});

function defaultDate(date: string) {
  return format(addDays(new Date(date), daysInWeek), "yyyy-MM-dd'T'HH:mm");
}

const routineAttributes = ref<RoutineAttributes>({
  ...props.routine,
  scheduledAt: defaultDate(props.routine.scheduledAt),
  published: false,
  noteForTrainer: props.routine.noteForTrainer || undefined,
  noteForTrainee: props.routine.noteForTrainee || undefined,
  originalId: props.routine.originalId || undefined,
});
const withAudios = ref(false);

const isSameWorkoutPlan = computed(() => routineAttributes.value.workoutPlanId === props.routine.workoutPlanId);

const {
  isError,
  isPending,
  isSuccess,
  error: cloneRoutineError,
  mutate: cloneRoutine,
  reset: resetCloneRoutine,
} = useMutation({
  mutationFn: (attributes: RoutineAttributes & { withAudios: boolean }) => routinesApi.clone({
    routineId: props.routine.id,
    scheduledAt: attributes.scheduledAt,
    workoutPlanId: attributes.workoutPlanId,
    withAudios: attributes.withAudios,
  }),
});

function handleClose() {
  routineAttributes.value = {
    ...props.routine,
    scheduledAt: defaultDate(props.routine.scheduledAt),
    published: false,
    noteForTrainer: props.routine.noteForTrainer || undefined,
    noteForTrainee: props.routine.noteForTrainee || undefined,
    originalId: props.routine.originalId || undefined,
  };
  withAudios.value = false;
  const isCloneRoutineSuccess = isSuccess.value;
  resetCloneRoutine();
  emit('close', isCloneRoutineSuccess);
}

function handleWorkoutPlanChange(value: number) {
  routineAttributes.value.workoutPlanId = value;
  if (!isSameWorkoutPlan.value) {
    withAudios.value = false;
  }
}

</script>

<template>
  <BaseModal
    :open="isOpen"
    title="Clonar rutina"
    @close="handleClose"
  >
    <div
      v-if="isSuccess"
      class="flex flex-col gap-8"
    >
      <span>
        Rutina clonada exitosamente
      </span>
      <BaseButton @click="handleClose">
        Cerrar
      </BaseButton>
    </div>
    <div
      v-else-if="isPending"
      class="flex flex-col gap-8"
    >
      Cargando...
    </div>
    <div
      v-else-if="isError"
      class="flex flex-col gap-8"
    >
      <span>
        Ocurrió un error al clonar la rutina
      </span>
      <span
        v-if="(cloneRoutineError instanceof AxiosError)"
        class="whitespace-pre-wrap text-sm"
      >
        {{ cloneRoutineError.response?.data }}
      </span>
      <BaseButton @click="resetCloneRoutine">
        Reintentar
      </BaseButton>
    </div>
    <div
      v-else-if="routineAttributes !== undefined"
      class="flex flex-col gap-8"
    >
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Plan de entrenamiento"
          name="newRoutine.workoutPlanId"
          required
        />
        <BaseCombobox
          :model-value="routineAttributes.workoutPlanId"
          name="newRoutine.workoutPlanId"
          :options="workoutPlanOptions"
          @update:model-value="(value) => handleWorkoutPlanChange(Number(value))"
        />
      </div>
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Fecha"
          name="newRoutine.scheduledAt"
          required
        />
        <BaseInput
          v-model="routineAttributes.scheduledAt"
          name="newRoutine.scheduledAt"
          type="datetime-local"
        />
      </div>
      <div class="flex items-center gap-2">
        <BaseCheckbox
          v-model="withAudios"
          :disabled="!isSameWorkoutPlan"
          name="newRoutine.withAudios"
        />
        <label
          class="text-sm"
          :class="{ 'opacity-50': !isSameWorkoutPlan }"
          for="newRoutine.withAudios"
        >
          Incluir audios
        </label>
      </div>
      <div class="flex justify-end gap-2">
        <BaseButton
          variant="outline"
          @click="handleClose"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          v-if="routineAttributes !== undefined"
          :disabled="routineAttributes.scheduledAt === ''"
          @click="cloneRoutine({ ...routineAttributes, withAudios })"
        >
          Clonar rutina
        </BaseButton>
      </div>
    </div>
  </BaseModal>
</template>
