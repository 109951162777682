<script setup lang="ts">
import { ref, computed } from 'vue';
import { Ellipsis } from 'lucide-vue-next';
import type { Routine } from '@/types/extended';
import { useQueryClient } from '@tanstack/vue-query';
import { analytics } from '@/utils/analytics';
import { BaseButton, BaseMenu } from '.';
import NewRoutineTemplateModal from './new-routine-template-modal.vue';
import CloneRoutineModal from './clone-routine-modal.vue';
import DeleteRoutineModal from './delete-routine-modal.vue';
import ProgressRoutineModal from './progress-routine-modal.vue';
import DiffRoutineModal from './diff-routine-modal.vue';

const props = defineProps<{
  routine: Routine;
}>();

const isNewRoutineTemplateModalOpen = ref(false);
const isCloneRoutineOpen = ref(false);
const isDeleteRoutineOpen = ref(false);
const isProgressRoutineOpen = ref(false);
const isDiffRoutineOpen = ref(false);

const queryClient = useQueryClient();
function invalidateRoutinesQueries() {
  queryClient.invalidateQueries({ queryKey: ['routinePhase'] });
  queryClient.invalidateQueries({ queryKey: ['routines'] });
}

// eslint-disable-next-line complexity
function handleOpenModal(modal: string) {
  if (analytics) analytics.capture(`${modal}_routine_modal_open`, { routineId: props.routine.id });
  switch (modal) {
  case 'clone':
    isCloneRoutineOpen.value = true;
    break;
  case 'delete':
    isDeleteRoutineOpen.value = true;
    break;
  case 'progress':
    isProgressRoutineOpen.value = true;
    break;
  case 'diff':
    isDiffRoutineOpen.value = true;
    break;
  case 'new_routine_template':
    isNewRoutineTemplateModalOpen.value = true;
    break;
  default:
    break;
  }
}

const isDiffRoutineEnabled = computed(() => props.routine.diff !== null);

const menuItems = computed(() => [
  { label: 'Clonar', onClick: () => handleOpenModal('clone') },
  { label: 'Progresar', onClick: () => handleOpenModal('progress') },
  ...(isDiffRoutineEnabled.value ? [{ label: 'Comparar', onClick: () => handleOpenModal('diff') }] : []),
  { label: 'Guardar como plantilla', onClick: () => handleOpenModal('new_routine_template') },
  { label: 'Eliminar', onClick: () => handleOpenModal('delete') },
]);

function handleProgressRoutineClose(isSuccess: boolean) {
  isProgressRoutineOpen.value = false;
  if (isSuccess) {
    invalidateRoutinesQueries();
  }
}

function handleDeleteRoutineClose(isSuccess: boolean) {
  isDeleteRoutineOpen.value = false;
  if (isSuccess) {
    invalidateRoutinesQueries();
  }
}

function handleCloneRoutineClose(isSuccess: boolean) {
  isCloneRoutineOpen.value = false;
  if (isSuccess) {
    invalidateRoutinesQueries();
  }
}
</script>

<template>
  <BaseMenu
    class="right-0"
    :items="menuItems"
  >
    <template #button>
      <BaseButton
        variant="ghost"
        size="sm"
      >
        <Ellipsis class="size-4" />
      </BaseButton>
    </template>
  </BaseMenu>
  <NewRoutineTemplateModal
    :routine="props.routine"
    :is-open="isNewRoutineTemplateModalOpen"
    @close="isNewRoutineTemplateModalOpen = false"
  />
  <CloneRoutineModal
    :routine="props.routine"
    :is-open="isCloneRoutineOpen"
    @close="handleCloneRoutineClose"
  />
  <DeleteRoutineModal
    :routine="props.routine"
    :is-open="isDeleteRoutineOpen"
    @close="handleDeleteRoutineClose"
  />
  <ProgressRoutineModal
    :routine="props.routine"
    :is-open="isProgressRoutineOpen"
    @close="handleProgressRoutineClose"
  />
  <DiffRoutineModal
    :routine="props.routine"
    :is-open="isDiffRoutineOpen"
    @close="isDiffRoutineOpen = false"
  />
</template>
