<script setup lang="ts">
import { ref, toRefs } from 'vue';
import { BaseButton, BaseModal } from '@/components';
import type { TraineeNote } from '@/types';
import { formatDate } from '@/utils/format-date';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { traineeNotesApi } from '@/api';
import { PencilIcon, TrashIcon } from 'lucide-vue-next';

const props = defineProps<{
    traineeId: number,
}>();

const showNoteModal = ref(false);
const noteContent = ref('');
const editingNoteId = ref<number | null>(null);
const showDeleteConfirmModal = ref(false);
const deletingNoteId = ref<number | null>(null);

const queryClient = useQueryClient();

const { traineeId } = toRefs(props);

const { mutate: createNote } = useMutation({
  mutationFn: (note: Pick<TraineeNote, 'content' | 'traineeId'>) => traineeNotesApi.create(note),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['traineeNotes', traineeId] });
  },
});

const { mutate: updateNote } = useMutation({
  mutationFn: (note: Pick<TraineeNote, 'id' | 'content'>) => traineeNotesApi.update(note),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['traineeNotes', traineeId] });
  },
});

const { mutate: deleteNote } = useMutation({
  mutationFn: (noteId: number) => traineeNotesApi.delete(noteId),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['traineeNotes', traineeId] });
  },
});

const { data: notes } = useQuery({
  queryKey: ['traineeNotes', traineeId],
  queryFn: () => traineeNotesApi.getAll({ traineeId: traineeId.value }),
});

function toggleNoteModal(note?: TraineeNote) {
  if (note) {
    editingNoteId.value = note.id;
    noteContent.value = note.content;
  } else {
    editingNoteId.value = null;
    noteContent.value = '';
  }
  showNoteModal.value = !showNoteModal.value;
}

function saveNoteAndCloseModal() {
  if (editingNoteId.value) {
    updateNote({ id: editingNoteId.value, content: noteContent.value });
  } else {
    createNote({ content: noteContent.value, traineeId: traineeId.value });
  }
  noteContent.value = '';
  toggleNoteModal();
}

function confirmDeleteNote(noteId: number) {
  deletingNoteId.value = noteId;
  showDeleteConfirmModal.value = true;
}

function deleteNoteAndCloseModal() {
  if (deletingNoteId.value) {
    deleteNote(deletingNoteId.value);
  }
  showDeleteConfirmModal.value = false;
  deletingNoteId.value = null;
}

</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="flex items-center justify-between">
      <h2 class="text-xl font-bold">
        Notas
      </h2>
      <base-button
        variant="secondary"
        @click="toggleNoteModal()"
      >
        Agregar nota
      </base-button>
      <BaseModal
        :title="editingNoteId ? 'Editar nota' : 'Agregar nota'"
        :open="showNoteModal"
        @close="toggleNoteModal"
      >
        <div class="flex flex-col gap-4">
          <textarea
            v-model="noteContent"
            class="form-textarea h-32 w-full rounded border border-slate-200 bg-white px-3 py-2 text-sm ring-offset-white focus-within:border-slate-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
            placeholder="Escribe tu nota aquí"
          />
          <div class="flex justify-end gap-4">
            <base-button
              variant="secondary"
              class="rounded-lg border border-black px-4 py-2"
              @click="toggleNoteModal"
            >
              Cancelar
            </base-button>
            <base-button
              class="rounded-lg bg-black px-4 py-2 text-white"
              @click="saveNoteAndCloseModal"
            >
              Guardar
            </base-button>
          </div>
        </div>
      </BaseModal>
    </div>
    <template v-if="notes?.length !== 0">
      <div
        v-for="note in notes"
        :key="note.id"
        class="flex flex-col gap-2 rounded-lg border border-gray-200 bg-gray-50 p-4"
      >
        <div class="flex items-center justify-between">
          <span class="text-xs text-gray-500">
            {{ formatDate(note.updatedAt, {dateStyle: 'full', timeStyle: 'short'}) }}
          </span>
          <div class="flex gap-4">
            <BaseButton
              size="icon"
              variant="ghost"
              class="text-gray-600 hover:text-gray-900"
              @click="toggleNoteModal(note)"
            >
              <PencilIcon class="size-4" />
            </BaseButton>
            <BaseButton
              size="icon"
              variant="ghost"
              class="text-gray-600 hover:text-gray-900"
              @click="confirmDeleteNote(note.id)"
            >
              <TrashIcon class="size-4" />
            </BaseButton>
          </div>
        </div>
        <p class="whitespace-pre-line text-sm">
          {{ note.content }}
        </p>
      </div>
    </template>
    <template v-else>
      <p>
        No hay notas para mostrar.
      </p>
    </template>
  </div>

  <BaseModal
    title="Confirmar eliminación"
    :open="showDeleteConfirmModal"
    @close="showDeleteConfirmModal = false"
  >
    <p>¿Estás seguro de que quieres eliminar esta nota?</p>
    <div class="mt-4 flex justify-end gap-4">
      <BaseButton
        variant="secondary"
        @click="showDeleteConfirmModal = false"
      >
        Cancelar
      </BaseButton>
      <BaseButton
        variant="destructive"
        @click="deleteNoteAndCloseModal"
      >
        Eliminar
      </BaseButton>
    </div>
  </BaseModal>
</template>
