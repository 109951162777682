<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { createRoot, type Root } from 'react-dom/client';
import { createElement } from 'react';
import type { User } from '@/types';
import { ChatLayout } from './chat-layout';

const props = defineProps<{
  user: User;
  appKey: string;
}>();

const reactRoot = ref<HTMLDivElement | null>(null);
let root: Root | null = null;

onMounted(() => {
  if (reactRoot.value) {
    root = createRoot(reactRoot.value);
    root.render(createElement(ChatLayout, { userId: props.user.id.toString(), appKey: props.appKey }));
  }
});

onBeforeUnmount(() => {
  if (root) {
    root.unmount();
  }
});
</script>

<template>
  <div
    ref="reactRoot"
    class="flex size-full items-center justify-center overflow-hidden"
  />
</template>
