<script setup lang="ts">
import { ref } from 'vue';
import { Menu, X, Users, MessageSquare, Dumbbell, Calendar, Apple } from 'lucide-vue-next';
import { useStreamChat } from '@/composables/stream-chat';

const props = defineProps<{
  userId: string;
  streamChatAppKey: string;
  controllerPath: string;
}>();

const { unreadChannels } = useStreamChat(props.userId, props.streamChatAppKey);

const hasSidebar = ref(
  ['trainees', 'teams', 'exercises', 'routines', 'workouts', 'chats', 'nutrition_plans'].includes(props.controllerPath),
);
const isSidebarOpen = ref(false);

function toggleSidebar() {
  isSidebarOpen.value = !isSidebarOpen.value;
}

const sidebarItems = [
  {
    name: 'Trainees',
    path: '/',
    controllerPath: 'trainees',
    icon: Users,
  },
  {
    name: 'Nutrición',
    path: '/nutrition_plans',
    controllerPath: 'nutrition_plans',
    icon: Apple,
  },
  {
    name: 'Chats',
    path: '/chats',
    controllerPath: 'chats',
    icon: MessageSquare,
    badge: unreadChannels,
  },
  {
    name: 'Ejercicios',
    path: '/exercises',
    controllerPath: 'exercises',
    icon: Dumbbell,
  },
  {
    name: 'Agenda',
    path: '/routines/schedule',
    controllerPath: '/routines/schedule',
    icon: Calendar,
  },
];
</script>

<template>
  <div class="flex h-full">
    <template v-if="hasSidebar">
      <aside
        class="absolute left-0 top-0 z-40 h-screen bg-white pt-14 shadow-md transition-all duration-300"
        :class="[
          isSidebarOpen ? 'w-48' : 'w-14',
          isSidebarOpen ? 'translate-x-0' : 'translate-x-0'
        ]"
      >
        <div class="m-2 h-full overflow-y-auto">
          <ul class="space-y-2 font-medium">
            <li
              v-for="item in sidebarItems"
              :key="item.name"
            >
              <a
                :href="item.path"
                class="flex items-center rounded-lg p-2 text-gray-900 hover:bg-gray-100"
                :class="[
                  { 'bg-gray-100': item.controllerPath === props.controllerPath },
                  isSidebarOpen ? 'justify-start' : 'justify-center',
                ]"
              >
                <div class="relative flex items-center">
                  <component
                    :is="item.icon"
                    class="size-5 shrink-0"
                  />
                  <span
                    v-if="item.badge && item.badge.value > 0"
                    class="absolute -right-2 -top-2 inline-flex h-5 min-w-5 shrink-0 items-center justify-center rounded-full bg-red-500 px-1 text-xs font-semibold text-white"
                  >
                    {{ item.badge.value }}
                  </span>
                </div>
                <span
                  class="ml-3 text-sm transition-opacity duration-300"
                  :class="{ 'hidden': !isSidebarOpen, 'block': isSidebarOpen }"
                >
                  {{ item.name }}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </aside>
      <button
        type="button"
        class="fixed left-0 top-0 z-50 m-2 rounded-md bg-white p-2 transition-colors duration-200 hover:bg-gray-100"
        @click="toggleSidebar"
      >
        <span class="sr-only">Toggle sidebar</span>
        <Menu
          v-if="!isSidebarOpen"
          class="size-6"
        />
        <X
          v-else
          class="size-6"
        />
      </button>
    </template>
    <main
      class="h-screen flex-1 overflow-y-auto transition-all duration-300"
      :class="{ 'sm:ml-48': isSidebarOpen, 'sm:ml-14': !isSidebarOpen && hasSidebar }"
    >
      <slot />
    </main>
  </div>
</template>
