import type { PreMeetingSurvey, TallySubmissionPayload } from '@/types';
import { api } from './axios-wrapper';

export const preMeetingSurveysApi = {
  async create(traineeId: number, submission: Partial<TallySubmissionPayload>) {
    const path = '/api/internal/pre_meeting_surveys';

    const response = await api({
      method: 'post',
      url: path,
      data: { preMeetingSurvey: { ...submission, traineeId } },
    });

    return response.data as PreMeetingSurvey;
  },
};
