<script setup lang="ts">
import { analytics } from '@/utils/analytics';
import type { TallySubmissionPayload } from '@/types/';
import { initialSurveySubmissionsApi } from '@/api';
import { useTallyForm } from '@/composables/tally-form';

interface PageViewPayload {
	formId: string;
	page: number;
}

const props = defineProps<{
  waitlistEnabled: boolean;
}>();

function captureSubmission(payload: TallySubmissionPayload, email?: string) {
  analytics.capture('initial_survey_form_submitted', { ...payload, email });
}

async function handleSubmission(payload: TallySubmissionPayload) {
  const email = payload.fields.find((field) => field.title === 'Email')?.answer.value;
  await initialSurveySubmissionsApi.create(payload);

  document.cookie = `lead_email=${email}; path=/`;
  if (!props.waitlistEnabled) {
    window.location.href = '/onboarding/trainers';
  }

  captureSubmission(payload, email);
}

function capturePageView(payload: PageViewPayload) {
  analytics?.capture('initial_survey_page_view', payload);
}

function handleTallyMessage(e: MessageEvent) {
  const payload = JSON.parse(e.data).payload;
  if (e?.data?.includes('Tally.FormSubmitted')) {
    handleSubmission(payload);
  } else if (e?.data?.includes('Tally.FormPageView')) {
    capturePageView(payload);
  }
}

useTallyForm(handleTallyMessage);
</script>

<template>
  <div>
    <iframe
      data-tally-src="https://tally.so/embed/wzq1yR?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
      loading="lazy"
      width="100%"
      height="1200"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      title="Kapso"
    />
  </div>
</template>
