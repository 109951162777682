<script setup lang="ts">
import { computed } from 'vue';
import { formatRelative } from 'date-fns';
import type { ChatGroup, Trainee } from '@/types/extended';
import type { Trainer, ChatMessage } from '@/types';

const props = defineProps<{
  chatGroup: ChatGroup,
  trainee: Trainee,
  trainer: Trainer | undefined,
}>();

const messages = computed(() => props.chatGroup.chatMessages);

function userName(message: ChatMessage) {
  if (message.isBot) {
    return 'Kap';
  }

  if (message.userableType === 'Trainer') {
    return props.trainer?.fullName || 'Entrenador';
  }

  return props.trainee.fullName;
}
</script>

<template>
  <div class="flex flex-col gap-y-4">
    <h3 class="text-xl font-bold">
      WhatsApp
    </h3>
    <div class="flex flex-col gap-4">
      <div
        v-for="message in messages"
        :key="message.id"
        class="flex min-w-72 flex-col gap-y-1 rounded-md border bg-gray-50 p-2"
        :class="{
          'self-end': message.userableType === 'Trainer' || message.isBot,
          'self-start': message.userableType === 'Trainee',
        }"
      >
        <p class="flex gap-x-1">
          <span
            class="text-sm font-semibold"
            :class="{
              'text-sky-600': message.userableType === 'Trainer',
              'text-green-700': message.userableType === 'Trainee',
              'text-red-900': message.isBot,
            }"
          >
            {{ userName(message) }}
          </span>
        </p>
        <p class="whitespace-pre-line text-sm">
          {{ message.textContent }}
        </p>
        <span class="self-end text-xs text-gray-500">
          {{ formatRelative(new Date(message.createdAt), new Date()) }}
        </span>
      </div>
    </div>
  </div>
</template>
