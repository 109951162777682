import React, { useCallback } from 'react';

import {
  Chat,
  Channel,
  ChannelList,
  ChannelHeader,
  LoadingIndicator,
  MessageList,
  MessageInput,
  Window,
  useCreateChatClient,
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/v2/index.css';
import '@/css/chat.css';
import { chatTokensApi } from '@/api';

const defaultLanguage = 'es';

function ChatLayout({ userId, appKey }: { userId: string; appKey: string }) {
  const getToken = useCallback(
    async () => await chatTokensApi.create({ userId }),
    [userId],
  );

  const filters = { members: { $in: [userId] }, type: 'messaging' };
  const options = { presence: true, state: true };

  const client = useCreateChatClient({
    apiKey: appKey,
    tokenOrProvider: getToken,
    userData: { id: userId },
  });

  if (!client) return <LoadingIndicator />;

  return (
    <Chat
      client={client}
      defaultLanguage={defaultLanguage}
    >
      <ChannelList filters={filters} options={options} />
      <Channel>
        <Window>
          <ChannelHeader />
          <MessageList />
          <MessageInput />
        </Window>
      </Channel>
    </Chat>
  );
}

export { ChatLayout };
