<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  modelValue: boolean;
  name: string;
  disabled?: boolean;
  checkedColor?: string;
  uncheckedColor?: string;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  checkedColor: 'bg-black',
  uncheckedColor: 'bg-gray-200',
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'change', value: boolean): void;
}>();

const isChecked = computed(() => props.modelValue);

function toggle() {
  if (!props.disabled) {
    const newValue = !isChecked.value;
    emit('update:modelValue', newValue);
    emit('change', newValue);
  }
}

const switchClasses = computed(() => [
  isChecked.value ? props.checkedColor : props.uncheckedColor,
  props.disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
]);

const toggleClasses = computed(() => [
  isChecked.value ? 'translate-x-[22px]' : 'translate-x-0.5',
]);
</script>

<template>
  <button
    type="button"
    class="relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
    :class="switchClasses"
    :aria-checked="isChecked"
    :disabled="disabled"
    @click="toggle"
  >
    <span
      class="inline-block size-5 rounded-full bg-white transition-transform"
      :class="toggleClasses"
      aria-hidden="true"
    />
  </button>
</template>
