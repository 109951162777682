import { onMounted, onBeforeUnmount, type Ref } from 'vue';

export function useBeforeUnloadWarning(showWarning: Ref<boolean>) {
  function handleBeforeUnload(event: BeforeUnloadEvent) {
    if (showWarning.value) {
      event.preventDefault();
    }
  }

  onMounted(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  });
}
