<script setup lang="ts">
import { computed } from 'vue';
import { followUpMeetingSurveysApi } from '@/api';
import type { TallySubmissionPayload, FollowUpMeetingSurvey } from '@/types';
import { useTallyForm } from '@/composables/tally-form';

const props = defineProps<{
  traineeId: number;
  tallyFormId: string;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'submitted', survey: FollowUpMeetingSurvey): void;
}>();

async function handleSubmission(payload: TallySubmissionPayload) {
  const response = await followUpMeetingSurveysApi.create(props.traineeId, payload);
  emit('submitted', response);
}

function handleTallyMessage(e: MessageEvent) {
  if (e?.data?.includes('Tally.FormSubmitted')) {
    const payload = JSON.parse(e.data).payload as TallySubmissionPayload;
    handleSubmission(payload);
  }
}

useTallyForm(handleTallyMessage);

const tallyFormUrl = computed(
  () => `https://tally.so/embed/${props.tallyFormId}?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1`,
);
</script>

<template>
  <div class="fixed inset-0 flex items-center justify-center overflow-y-auto bg-gray-600/50">
    <div class="flex size-3/4 flex-col rounded-lg bg-white p-8 shadow-lg">
      <div class="flex items-center justify-between p-4">
        <h2 class="text-xl font-semibold">
          Formulario de Seguimiento
        </h2>
        <button
          class="text-gray-500 hover:text-gray-700"
          @click="emit('close')"
        >
          <span class="sr-only">Close</span>
          <svg
            class="size-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div class="grow overflow-y-auto">
        <iframe
          :data-tally-src="tallyFormUrl"
          loading="lazy"
          width="100%"
          height="100%"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          title="Survey de Seguimiento"
        />
      </div>
    </div>
  </div>
</template>
