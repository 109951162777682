import { ref, onMounted } from 'vue';
import { StreamChat } from 'stream-chat';
import { chatTokensApi } from '@/api';

export function useStreamChat(userId: string, appKey: string) {
  const unreadChannels = ref(0);

  async function initializeClient() {
    const client = StreamChat.getInstance(appKey);

    const user = await client.connectUser(
      { id: userId },
      () => chatTokensApi.create({ userId }),
    );

    unreadChannels.value = user?.me?.unread_channels || 0;

    client.on(event => {
      if (event.unread_channels !== undefined) {
        unreadChannels.value = event.unread_channels;
      }
    });

    return client;
  }

  onMounted(async () => {
    await initializeClient();
  });

  return {
    unreadChannels,
  };
}
