<script setup lang="ts">
import { ref, computed } from 'vue';
import { useUrlSearchParams } from '@vueuse/core';
import { useQuery } from '@tanstack/vue-query';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue';
import type { Trainer, FollowUpMeetingSurvey, Nutritionist } from '@/types';
import type { Trainee, WorkoutPlan } from '@/types/extended';
import { traineesApi } from '@/api';
import { analytics } from '@/utils/analytics';
import { BaseLink, BaseInput } from '@/components';
import TraineeInfo from '@/components/trainee-info.vue';
import { ArrowRight } from 'lucide-vue-next';
import ChatGroupMessages from './chat-group-messages.vue';
import AiAssistant from '../ai-assistant.vue';
import NotesList from './notes-list.vue';
import RoutinesCalendar from '../routines-calendar.vue';
import RpeChart from './rpe-chart.vue';
import ExerciseExecutions from './exercise-executions.vue';
import TraineeStats from './trainee-stats.vue';
import SurveysList from './surveys-list.vue';

const props = defineProps<{
  trainees: Trainee[],
  trainer?: Trainer,
  nutritionist?: Nutritionist,
  openaiApiKey: string,
}>();

const searchParams = useUrlSearchParams('history');
const selectedTraineeId = ref<number | null>(
  searchParams.traineeId ? Number(searchParams.traineeId) : props.trainees[0]?.id,
);

const { data: selectedTrainee } = useQuery({
  queryKey: ['trainee', selectedTraineeId],
  queryFn: () => traineesApi.get(selectedTraineeId.value!),
  enabled: computed(() => selectedTraineeId.value !== null),
});

const workoutPlan = computed<WorkoutPlan | undefined>(() =>
  selectedTrainee.value?.workoutPlans[0],
);

const selectedTab = ref('routines');

const tabs = [
  {
    name: 'routines',
    label: 'Rutinas',
  },
  {
    name: 'stats',
    label: 'Estadísticas',
  },
  {
    name: 'assistant',
    label: 'Asistente IA',
  },
  {
    name: 'messages',
    label: 'Mensajes',
  },
  {
    name: 'notes',
    label: 'Notas',
  },
  {
    name: 'exercise_executions',
    label: 'Historial',
  },
  {
    name: 'surveys',
    label: 'Formularios',
  },
];

function normalizeText(text: string): string {
  return text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

const searchQuery = ref('');

const filteredTrainees = computed(() => {
  const query = normalizeText(searchQuery.value);
  if (!query) return props.trainees;

  return props.trainees.filter(trainee =>
    normalizeText(trainee.fullName).includes(query),
  );
});

function selectTrainee(traineeId: number) {
  selectedTab.value = 'routines';
  selectedTraineeId.value = traineeId;
  searchParams.traineeId = traineeId.toString();
  if (analytics) analytics.capture('select_trainee_click', { traineeId });
}

function tabChanged(index: number) {
  if (analytics) analytics.capture('trainee_tab_click', { tab: tabs[index].name });
}

function onFollowUpSurveySubmitted(newSurvey: FollowUpMeetingSurvey) {
  if (selectedTrainee.value) {
    selectedTrainee.value.followUpMeetingSurveys.push(newSurvey);
  }
}

</script>

<template>
  <div class="w-full">
    <h2 class="text-2xl font-bold">
      Trainees
    </h2>
    <div class="mt-4 flex gap-4">
      <div class="flex w-48 flex-col gap-2">
        <BaseInput
          v-model="searchQuery"
          type="text"
          placeholder="Buscar trainee"
          name="searchQuery"
        />
        <div class="flex max-h-[calc(100vh-200px)] w-48 flex-col overflow-y-auto">
          <button
            v-for="trainee in filteredTrainees"
            :key="trainee.id"
            class="flex cursor-pointer justify-between rounded-lg border p-3 text-sm transition-colors duration-200 hover:bg-gray-200"
            :class="{ 'border-2 bg-gray-200': selectedTrainee?.id === trainee.id }"
            @click="selectTrainee(trainee.id)"
          >
            <span class="truncate">{{ trainee.fullName }}</span>
          </button>
        </div>
      </div>
      <div
        v-if="selectedTrainee"
        class="w-full rounded-lg border bg-gray-50 p-4"
      >
        <div class="flex flex-col gap-4">
          <div class="flex justify-between gap-2">
            <div class="flex flex-col gap-1">
              <h3 class="text-xl font-bold">
                {{ selectedTrainee?.fullName }}
              </h3>
              <p class="text-sm">
                {{ selectedTrainee?.email }}
              </p>
            </div>
            <BaseLink :href="`/trainees/${selectedTrainee.id}/edit`">
              Editar <ArrowRight class="size-4" />
            </BaseLink>
          </div>
          <TraineeInfo :trainee="selectedTrainee" />
          <TabGroup @change="tabChanged">
            <TabList class="flex gap-1">
              <Tab
                v-for="tab in tabs"
                :key="tab.name"
                v-slot="{ selected }"
                class="w-28 cursor-pointer rounded-lg border p-2 text-base"
              >
                <p :class="{'border-b border-black font-semibold': selected}">
                  {{ tab.label }}
                </p>
              </Tab>
            </TabList>
            <TabPanels class="rounded-lg border bg-white p-4">
              <TabPanel class="flex flex-col gap-6">
                <div class="flex flex-col gap-y-2">
                  <BaseLink
                    :href="`/workout_plans/${workoutPlan?.id}/routines`"
                    class="w-min"
                  >
                    Ver rutinas por semana
                    <ArrowRight class="size-4" />
                  </BaseLink>
                </div>
                <div class="flex w-full flex-col gap-y-4">
                  <routines-calendar
                    v-if="selectedTrainee"
                    :routines="selectedTrainee.routines"
                  />
                  <rpe-chart
                    v-if="selectedTrainee"
                    :key="selectedTrainee.id"
                    :trainee="selectedTrainee"
                  />
                </div>
              </TabPanel>
              <TabPanel class="flex flex-col gap-6">
                <trainee-stats
                  v-if="selectedTrainee && workoutPlan"
                  :key="selectedTrainee.id"
                  :routines="selectedTrainee.routines"
                  :workout-plan-id="workoutPlan.id"
                  :trainee-id="selectedTrainee.id"
                />
              </TabPanel>
              <TabPanel class="flex flex-col gap-6">
                <ai-assistant
                  v-if="workoutPlan"
                  :key="selectedTrainee.id"
                  class="h-full"
                  :trainee="selectedTrainee"
                  :workout-plan-id="workoutPlan.id"
                  :chat-messages="selectedTrainee.trainerChatGroup.chatMessages"
                  :openai-api-key="props.openaiApiKey"
                  :is-intercom-style="false"
                />
              </TabPanel>
              <TabPanel class="flex flex-col gap-6">
                <chat-group-messages
                  v-if="selectedTrainee.trainerChatGroup"
                  :chat-group="selectedTrainee.trainerChatGroup"
                  :trainee="selectedTrainee"
                  :trainer="props.trainer"
                />
              </TabPanel>
              <TabPanel class="flex flex-col gap-6">
                <notes-list :trainee-id="selectedTrainee.id" />
              </TabPanel>
              <TabPanel class="flex flex-col gap-6">
                <exercise-executions
                  :key="selectedTrainee.id"
                  :trainee-id="selectedTrainee.id"
                />
              </TabPanel>
              <TabPanel class="flex flex-col gap-6">
                <surveys-list
                  v-if="selectedTrainee"
                  :key="selectedTrainee.id"
                  :initial-survey="selectedTrainee.initialSurveySubmission"
                  :pre-meeting-survey="selectedTrainee.preMeetingSurvey"
                  :follow-up-meeting-surveys="selectedTrainee.followUpMeetingSurveys"
                  :trainee-id="selectedTrainee.id"
                  @survey-submitted="onFollowUpSurveySubmitted"
                />
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      </div>
      <div
        v-else
        class="flex w-full flex-col items-center justify-center rounded-lg border bg-gray-50 p-4"
      >
        <span class="text-sm">
          Cargando...
        </span>
      </div>
    </div>
  </div>
</template>
