<script setup lang="ts">
import { computed } from 'vue';
import type { RoutineExerciseSet } from '@/types';
import type { RoutinePhaseExercise, WorkoutPhase } from '@/types/extended';

const props = defineProps<{
  workoutPhase?: WorkoutPhase;
  routinePhaseExercise: RoutinePhaseExercise;
  routineExerciseSet: RoutineExerciseSet;
}>();

const workoutExercise = computed(() => props.workoutPhase?.workoutExercises.find(
  we =>
    we.routinePhaseExerciseId === props.routinePhaseExercise.id &&
      we.setNumber === props.routineExerciseSet.setNumber,
));

const completedRepetitions = computed(() => workoutExercise.value?.repetitions || '-');
const completedWeight = computed(() => workoutExercise.value?.weight || '-');
const effort = computed(() => workoutExercise.value?.effort || '-');
const isCompleted = computed(() => (workoutExercise.value?.completedAt ? '✅' : '-'));
const traineeComment = computed(() => workoutExercise.value?.traineeComment || '-');

const repsDifference = computed(() => {
  if (!workoutExercise.value?.repetitions || !props.routineExerciseSet.repetitions) return '';

  const diff = workoutExercise.value.repetitions - props.routineExerciseSet.repetitions;

  return diff === 0 ? '' : `(${diff > 0 ? '+' : ''}${diff})`;
});

const weightDifference = computed(() => {
  if (!workoutExercise.value?.weight || !props.routineExerciseSet.weight) return '';

  const diff = workoutExercise.value.weight - props.routineExerciseSet.weight;

  return diff === 0 ? '' : `(${diff > 0 ? '+' : ''}${diff})`;
});
</script>

<template>
  <td class="border-b border-r px-3 py-2 text-center text-gray-500">
    {{ routineExerciseSet.setNumber }}
  </td>
  <td
    v-if="routineExerciseSet.repetitions"
    class="whitespace-nowrap border-b border-r px-3 py-2"
  >
    {{ `${completedRepetitions} / ${routineExerciseSet.repetitions}` }}
    <span class="ml-1 text-gray-500">{{ repsDifference }}</span>
  </td>
  <td
    v-else
    class="border-b border-r px-3 py-2"
  >
    {{ `${completedRepetitions} / -` }}
  </td>
  <td class="whitespace-nowrap border-b border-r px-3 py-2">
    <span v-if="routineExerciseSet.weight">
      {{ `${completedWeight} / ${routineExerciseSet.weight}` }}
      <span class="ml-1 text-gray-500">{{ weightDifference }}</span>
    </span>
    <span v-else>
      {{ `${completedWeight} / -` }}
    </span>
  </td>
  <td class="border-b border-r px-3 py-2">
    {{ effort }}
  </td>
  <td class="border-b border-r px-3 py-2">
    {{ isCompleted }}
  </td>
  <td class="border-b border-r px-3 py-2">
    {{ traineeComment }}
  </td>
</template>
