<script setup lang="ts">
import { ref } from 'vue';
import { format, parseISO } from 'date-fns';
import type { InitialSurveySubmission, SubmissionAnswer } from '@/types';
import LeadTable from './LeadTable.vue';
import LeadDetailsModal from './LeadDetailsModal.vue';
import SendMessageModal from './SendMessageModal.vue';

interface Lead extends InitialSurveySubmission {
  name: string;
  phoneNumber: string | null;
  whatsappNumber: string;
  city: string;
  countryCode: string;
  preferredCoachGender: string;
  experience: string;
  fitnessGoal: string;
  preferredTrainingLocation: string;
  plannedContactWeek: string | null;}

const props = defineProps<{
  initialSubmissions: InitialSurveySubmission[]
}>();

const leads = ref<Lead[]>([]);
const selectedLead = ref<Lead | null>(null);
const messageModalLead = ref<Lead | null>(null);
const initialMessage = ref('');

const statuses = ['new', 'contacted', 'in_conversation', 'link_sent', 'discarded', 'sold', 'invalid'];

function updateLead(updatedLead: Lead) {
  const index = leads.value.findIndex(lead => lead.id === updatedLead.id);
  if (index !== -1) {
    leads.value[index] = { ...leads.value[index], ...updatedLead };
  }
}

function openLeadModal(lead: Lead) {
  selectedLead.value = lead;
}

function formatWhatsAppNumber(phone: string): string {
  return phone.replace(/\D/g, '').replace(/^0+/, '56');
}

function generateInitialMessage(lead: Lead): string {
  const firstName = lead.name.split(' ')[0];
  const experienceAnswer = lead.submissionAnswers.find(a => a.title === '¿Cuál es tu experiencia haciendo ejercicio?');

  let message = `Hola ${firstName}! Qué tal? Soy Andrés Matte, fundador de Kapso (https://kap.so).

Te escribo porque completaste el formulario inicial en nuestra página web, buenísimo.

`;

  if (experienceAnswer) {
    switch (experienceAnswer.value) {
    case 'Antes hacía ejercicio y quiero volver a hacer':
      message += 'Vi que respondiste que antes hacías ejercicio y que quieres volver a hacer. Qué has hecho en el pasado?';
      break;
    case 'Soy nuev@ en esto':
      message += 'Vi que respondiste que eres nuev@ en esto. Qué has intentado hacer en el pasado?';
      break;
    case 'Actualmente estoy haciendo ejercicio regularmente':
      message += 'Vi que respondiste que actualmente estás haciendo ejercicio regularmente. Qué estás haciendo?';
      break;
    case 'Hago mucho deporte':
      message += 'Vi que respondiste que haces mucho deporte. Qué estás haciendo?';
      break;
    default:
      message += '';
      break;
    }
  }

  return message;
}

function openMessageModal(lead: Lead) {
  if (lead && lead.phoneNumber) {
    messageModalLead.value = lead;
    initialMessage.value = generateInitialMessage(lead);
  } else {
    console.error('Attempted to open message modal for lead without a phone number');
    // Optionally, show an error message to the user
  }
}

function transformSubmissionToLead(submission: InitialSurveySubmission): Lead {
  const getName = (answers: SubmissionAnswer[]) => {
    const firstName = answers.find(a => a.title === 'Nombre')?.value || '';
    const lastName = answers.find(a => a.title === 'Apellido')?.value || '';

    return `${firstName} ${lastName}`.trim();
  };

  const getCity = (answers: SubmissionAnswer[]) =>
    answers.find(a => a.title === '¿En qué ciudad vives?')?.value || '';

  const getCountryCode = (country: string) => {
    const countryCodes: { [key: string]: string } = {
      'Chile': '+56',
      'Argentina': '+54',
      'México': '+52',
      'España': '+34',
      'Colombia': '+57',
      'Perú': '+51',
      'Venezuela': '+58',
      'Ecuador': '+593',
      'Bolivia': '+591',
      'Paraguay': '+595',
      'Uruguay': '+598',
      'Costa Rica': '+506',
      'Panamá': '+507',
      'República Dominicana': '+1-809',
      'Honduras': '+504',
      'Guatemala': '+502',
      'Nicaragua': '+505',
      'El Salvador': '+503',
      'Estados Unidos': '+1',
    };

    return countryCodes[country] || '';
  };

  const getPreferredCoachGender = (answers: SubmissionAnswer[]) =>
    answers.find(a => a.title === 'Prefiero que mi coach sea')?.value || '';

  const getExperience = (answers: SubmissionAnswer[]) =>
    answers.find(a => a.title === '¿Cuál es tu experiencia haciendo ejercicio?')?.value || '';

  const getFitnessGoal = (answers: SubmissionAnswer[]) =>
    answers.find(a => a.title === '¿Cuál es tu principal meta de fitness?')?.value || '';

  const getPreferredTrainingLocation = (answers: SubmissionAnswer[]) =>
    answers.find(a => a.title === '¿Dónde te gustaría entrenar?')?.value || '';

  return {
    ...submission,
    name: getName(submission.submissionAnswers),
    phoneNumber: submission.phoneNumber || '',  // Use phoneNumber directly
    whatsappNumber: formatWhatsAppNumber(submission.phoneNumber || ''),
    status: submission.status || 'new',
    city: getCity(submission.submissionAnswers),
    countryCode: getCountryCode(submission.country),
    preferredCoachGender: getPreferredCoachGender(submission.submissionAnswers),
    experience: getExperience(submission.submissionAnswers),
    fitnessGoal: getFitnessGoal(submission.submissionAnswers),
    preferredTrainingLocation: getPreferredTrainingLocation(submission.submissionAnswers),
    contactedAt: submission.contactedAt ?
      format(parseISO(submission.contactedAt), 'yyyy-MM-dd') :
      null,
    plannedContactWeek: submission.plannedContactWeek ?
      format(parseISO(submission.plannedContactWeek), 'yyyy-MM-dd') :
      null,
  };
}

// Initialize leads when component is mounted
leads.value = props.initialSubmissions.map(transformSubmissionToLead);

</script>

<template>
  <div class="p-4">
    <h1 class="mb-4 text-2xl font-bold">
      Kapso CRM
    </h1>
    <LeadTable
      :leads="leads"
      :statuses="statuses"
      @view-details="openLeadModal"
      @send-message="openMessageModal"
      @update-lead="updateLead"
    />
    <LeadDetailsModal
      :lead="selectedLead"
      :statuses="statuses"
      @close="selectedLead = null"
      @update:lead="updateLead"
      @open-message-modal="openMessageModal"
    />
    <SendMessageModal
      :lead="messageModalLead"
      :initial-message="initialMessage"
      @close="messageModalLead = null"
    />
  </div>
</template>
