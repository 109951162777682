<script setup lang="ts">
import { ref, computed } from 'vue';
import type { InitialSurveySubmission, PreMeetingSurvey, FollowUpMeetingSurvey } from '@/types';
import FollowUpMeetingSurveyModal from './follow-up-meeting-survey-modal.vue';

const props = defineProps<{
  initialSurvey: InitialSurveySubmission | null;
  preMeetingSurvey: PreMeetingSurvey | null;
  followUpMeetingSurveys: FollowUpMeetingSurvey[];
  traineeId: number;
}>();

const emit = defineEmits<{
  (e: 'surveySubmitted', survey: FollowUpMeetingSurvey): void;
}>();

const localFollowUpMeetingSurveys = ref<FollowUpMeetingSurvey[]>(props.followUpMeetingSurveys);

const allSurveys = computed(() => {
  const surveys = [];
  if (props.initialSurvey) {
    surveys.push({
      type: 'Form Registro',
      date: new Date(props.initialSurvey.createdAt),
      answers: props.initialSurvey.submissionAnswers,
    });
  }
  if (props.preMeetingSurvey) {
    surveys.push({
      type: 'Form Pre Primera Reunión',
      date: new Date(props.preMeetingSurvey.createdAt),
      answers: props.preMeetingSurvey.submissionAnswers,
    });
  }

  localFollowUpMeetingSurveys.value.forEach(survey => {
    surveys.push({
      type: 'Form Reunión con Coach',
      date: new Date(survey.createdAt),
      answers: survey.submissionAnswers,
    });
  });

  return surveys.sort((a, b) => b.date.getTime() - a.date.getTime());
});

const expandedSurveys = ref<Set<number>>(new Set());

function toggleSurvey(index: number) {
  if (expandedSurveys.value.has(index)) {
    expandedSurveys.value.delete(index);
  } else {
    expandedSurveys.value.add(index);
  }
}

function formatDate(date: Date): string {
  return date.toLocaleDateString('es-ES', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
}

const showFollowUpSurveyModal = ref(false);
const currentFormId = ref('');

function openFollowUpSurveyModal(formId: string) {
  currentFormId.value = formId;
  showFollowUpSurveyModal.value = true;
}

function onSurveySubmitted(newSurvey: FollowUpMeetingSurvey) {
  localFollowUpMeetingSurveys.value.push(newSurvey);
  emit('surveySubmitted', newSurvey);
  showFollowUpSurveyModal.value = false;
}

const FIRST_MEETING_FORM_ID = 'nrLEPL';
const FOLLOW_UP_MEETING_FORM_ID = '31v25O';

</script>

<template>
  <div>
    <div class="mb-4 flex items-center justify-between">
      <h2 class="text-xl font-semibold">
        Formularios
      </h2>
      <div class="space-x-2">
        <button
          class="rounded border border-solid border-gray-300 bg-white px-4 py-2 text-sm"
          @click="openFollowUpSurveyModal(FIRST_MEETING_FORM_ID)"
        >
          Form Primera Reunión
        </button>
        <button
          class="rounded border border-solid border-gray-300 bg-white px-4 py-2 text-sm"
          @click="openFollowUpSurveyModal(FOLLOW_UP_MEETING_FORM_ID)"
        >
          Form Reunión Seguimiento
        </button>
      </div>
    </div>

    <div
      v-if="allSurveys.length > 0"
      class="space-y-4"
    >
      <div
        v-for="(survey, index) in allSurveys"
        :key="index"
        class="overflow-hidden rounded-lg border"
      >
        <button
          class="flex w-full items-center justify-between bg-gray-100 p-4 transition-colors duration-200 hover:bg-gray-200"
          @click="toggleSurvey(index)"
        >
          <div class="flex flex-col items-start">
            <h3 class="text-lg font-semibold">
              {{ survey.type }}
            </h3>
            <p class="text-sm text-gray-600">
              {{ formatDate(survey.date) }}
            </p>
          </div>
          <svg
            class="size-6 text-gray-600 transition-transform duration-200"
            :class="{ 'rotate-180': expandedSurveys.has(index) }"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
        <div
          v-if="expandedSurveys.has(index)"
          class="bg-white p-4"
        >
          <div
            v-for="answer in survey.answers"
            :key="answer.id"
            class="mb-4 last:mb-0"
          >
            <p class="mb-1 font-semibold text-gray-700">
              {{ answer.title }}
            </p>
            <p class="rounded bg-gray-50 p-2 text-gray-600">
              {{ answer.value }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="rounded-lg bg-gray-100 p-4 text-center text-gray-500"
    >
      No hay surveys disponibles para este trainee.
    </div>

    <FollowUpMeetingSurveyModal
      v-if="showFollowUpSurveyModal"
      :trainee-id="traineeId"
      :tally-form-id="currentFormId"
      @close="showFollowUpSurveyModal = false"
      @submitted="onSurveySubmitted"
    />
  </div>
</template>
