import type { RoutineTemplate, RoutineTemplateAttributes } from '@/types';
import { api } from './axios-wrapper';

export const routineTemplatesApi = {
  async getAll() {
    const path = '/api/internal/routine_templates';

    const response = await api({
      method: 'get',
      url: path,
    });

    return response.data as RoutineTemplate[];
  },
  async create(routineTemplateAttributes: RoutineTemplateAttributes) {
    const path = '/api/internal/routine_templates';

    const response = await api({
      method: 'post',
      url: path,
      data: { routineTemplate: routineTemplateAttributes },
    });

    return response.data as RoutineTemplate;
  },
};
