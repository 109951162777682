// Third party imports
import { VueQueryPlugin } from '@tanstack/vue-query';
import { setDefaultOptions } from 'date-fns';
import { es } from 'date-fns/locale';
import { camelizeKeys } from 'humps';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import InlineSvg from 'vue-inline-svg';
import InstantSearch from 'vue-instantsearch/vue3/es';
import VueApexCharts from 'vue3-apexcharts';

// Base components
import { BaseButton, BaseInput, BackButton } from '@/components';

// Feature components
import AiAssistant from '@/components/ai-assistant.vue';
import Crm from '@/components/crm/crm.vue';
import ExerciseForm from '@/components/exercise-form.vue';
import ExerciseSearch from '@/components/exercise-search.vue';
import ChooseTrainer from '@/components/onboarding/choose-trainer.vue';
import InitialSurvey from '@/components/onboarding/initial-survey.vue';
import OnboardingSurvey from '@/components/onboarding/onboarding-survey.vue';
import ChatWrapper from '@/components/chat-wrapper.vue';
import RebillCheckout from '@/components/onboarding/rebill-checkout.vue';
import RoutinesCalendar from '@/components/routines-calendar.vue';
import RoutinesSchedule from '@/components/routines-schedule.vue';
import TeamsInitialSurvey from '@/components/teams/initial-survey.vue';
import TeamsDashboard from '@/components/teams/teams-dashboard.vue';
import TheLayout from '@/components/the-layout.vue';
import TraineeForm from '@/components/trainee-form.vue';
import TraineesDashboard from '@/components/trainees-dashboard/trainees-dashboard.vue';
import WeeklyRoutinesView from '@/components/weekly-routines-view.vue';
import WorkoutDetails from '@/components/workout-details.vue';
import NutritionPlansList from '@/components/nutrition-plans/nutrition-plans-list.vue';
import NutritionPlanDetails from '@/components/nutrition-plans/nutrition-plan-details.vue';
import NutritionPlanForm from '@/components/nutrition-plans/nutrition-plan-form.vue';

// Utils
import { EncoderPlugin } from '@/utils/encoder-plugin';

// Styles
import '../css/application.css';

setDefaultOptions({ locale: es });

// eslint-disable-next-line max-statements
document.addEventListener('DOMContentLoaded', () => {
  const pinia = createPinia();

  const app = createApp({
    components: {
      AiAssistant,
      BackButton,
      BaseButton,
      BaseInput,
      ChooseTrainer,
      Crm,
      ExerciseForm,
      ExerciseSearch,
      InitialSurvey,
      OnboardingSurvey,
      ChatWrapper,
      RebillCheckout,
      RoutinesCalendar,
      RoutinesSchedule,
      TeamsDashboard,
      TeamsInitialSurvey,
      TheLayout,
      TraineeForm,
      TraineesDashboard,
      WeeklyRoutinesView,
      WorkoutDetails,
      NutritionPlansList,
      NutritionPlanDetails,
      NutritionPlanForm,
    },
  });

  app.config.globalProperties.$filters = {
    camelizeKeys,
  };
  app.component('InlineSvg', InlineSvg);

  app.use(pinia);
  app.use(VueQueryPlugin);
  app.use(InstantSearch);
  app.use(EncoderPlugin);
  app.use(VueApexCharts);

  app.mount('#vue-app');

  return app;
});
