import type { Trainer } from '@/types';
import { api } from './axios-wrapper';

export const trainersApi = {
  async getAll() {
    const response = await api({
      method: 'get',
      url: '/api/internal/trainers',
    });

    return response.data as Trainer[];
  },
};
