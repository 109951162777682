<script setup lang="ts">
import { toRef } from 'vue';
import { useField } from 'vee-validate';

defineOptions({
  inheritAttrs: false,
});

interface Props {
  type?: 'text' | 'number' | 'datetime-local'
  modelValue?: string | number | null
  placeholder?: string
  name: string
  is?: 'input' | 'textarea'
  required?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  is: 'input',
  modelValue: undefined,
  placeholder: undefined,
});

const name = toRef(props, 'name');

const {
  meta,
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
} = useField(name, undefined, {
  initialValue: props.modelValue,
  syncVModel: true,
});
</script>

<template>
  <div
    class="flex flex-col gap-1"
    :class="$attrs.class"
  >
    <div class="flex items-center">
      <slot name="icon" />
      <component
        v-bind="$attrs"
        :is="is"
        :id="name"
        :value="inputValue"
        :placeholder="placeholder"
        :name="name"
        :type="type"
        class="form-input w-full rounded border bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-within:border-slate-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        :class="[errorMessage && meta.touched ? 'border-red-600 text-red-600' : 'border-slate-200', {
          'pl-10': $slots.icon,
          'h-40': is === 'textarea',
          // eslint-disable-next-line max-len, vue/max-len
          '[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none': type === 'number',
        }]"
        @input="handleChange"
        @blur="handleBlur"
      />
    </div>
    <p
      v-show="errorMessage && meta.touched"
      class="text-left text-xs text-red-600"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
