<script setup lang="ts">
import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { exercisesApi, routinesApi } from '@/api';
import type { Routine } from '@/types/extended';
import { BaseModal, BaseButton } from '@/components';
import TextDiff from '@/components/text-diff.vue';

const props = defineProps<{
  routine: Routine;
  isOpen: boolean;
}>();

const emit = defineEmits<{
  close: [],
}>();

const { data: routine } = useQuery({
  queryKey: ['routine', props.routine.id],
  queryFn: () => routinesApi.get(props.routine.id),
  initialData: props.routine,
  enabled: () => props.isOpen,
});

const routineDiff = computed(() => routine.value?.diff);

const { data: exercises } = useQuery({
  queryKey: ['exercises'],
  queryFn: () => exercisesApi.getAll(),
  enabled: () => props.isOpen,
});

function handleClose() {
  emit('close');
}

function formatValue(value: string | number | null | undefined): string {
  if (value === null || value === undefined) return '';
  if (typeof value === 'string' && value.trim() === '') return '';

  return String(value);
}

function getChange(path: string) {
  return routineDiff.value?.changes?.find(c => c.path.join('.') === path);
}

function getExerciseName(exerciseId: string | number | null | undefined) {
  if (typeof exerciseId !== 'number') return null;

  return exercises.value?.find(e => e.id === exerciseId)?.name;
}

function isAddition(path: string) {
  return routineDiff.value?.additions?.some(a => a.path.join('.') === path) || false;
}

type SetData = {
  id: number;
  comment: string | null;
  commentChange: {
    oldValue: string;
    newValue: string;
  } | null;
  isAddition: boolean;
  repetitions: number | null;
  repetitionsChange: {
    oldValue: string;
    newValue: string;
  } | null;
  setNumber: number;
  weight: number | null;
  weightChange: {
    oldValue: string;
    newValue: string;
  } | null;
}

type ExerciseData = {
  id: number;
  duration: number;
  durationChange: {
    oldValue: string;
    newValue: string;
  } | null;
  isAddition: boolean;
  name: string;
  nameChange: {
    oldValue: string;
    newValue: string;
  } | null;
  rows: number;
  sets: SetData[];
}

type PhaseData = {
  id: number;
  exercises: ExerciseData[];
  isAddition: boolean;
  name: string;
  nameChange: {
    oldValue: string;
    newValue: string;
  } | null;
  rows: number;
}

const tableData = computed(() => {
  if (!routineDiff.value) return [];

  const data: PhaseData[] = [];

  props.routine.routinePhases.forEach((phase, phaseIndex) => {
    const phaseNameChange = getChange(`routine_phases.${phaseIndex}.name`);
    const phaseAddition = isAddition(`routine_phases.${phaseIndex}`);

    const phaseData: PhaseData = {
      id: phase.id,
      name: phase.name,
      nameChange: phaseNameChange ? {
        oldValue: formatValue(phaseNameChange.oldValue),
        newValue: formatValue(phaseNameChange.newValue),
      } : null,
      isAddition: phaseAddition,
      exercises: [],
      rows: phase.routinePhaseExercises.reduce((total, ex) => total + ex.routineExerciseSets.length, 0),
    };

    phase.routinePhaseExercises.forEach((exercise, exerciseIndex) => {
      const exerciseChange = getChange(
        `routine_phases.${phaseIndex}.routine_phase_exercises.${exerciseIndex}.exercise_id`,
      );
      const durationChange = getChange(
        `routine_phases.${phaseIndex}.routine_phase_exercises.${exerciseIndex}.duration`,
      );
      const exerciseAddition = isAddition(`routine_phases.${phaseIndex}.routine_phase_exercises.${exerciseIndex}`);

      const exerciseData: ExerciseData = {
        id: exercise.id,
        name: exercise.exerciseName,
        nameChange: exerciseChange ? {
          oldValue: formatValue(getExerciseName(exerciseChange.oldValue)),
          newValue: formatValue(getExerciseName(exerciseChange.newValue)),
        } : null,
        duration: exercise.durationInSeconds,
        durationChange: durationChange ? {
          oldValue: formatValue(durationChange.oldValue),
          newValue: formatValue(durationChange.newValue),
        } : null,
        isAddition: exerciseAddition,
        sets: [],
        rows: exercise.routineExerciseSets.length,
      };

      exercise.routineExerciseSets.forEach((set, setIndex) => {
        const basePath =
          `routine_phases.${phaseIndex}.routine_phase_exercises.${exerciseIndex}.routine_exercise_sets.${setIndex}`;
        const repetitionsChange = getChange(`${basePath}.repetitions`);
        const weightChange = getChange(`${basePath}.weight`);
        const commentChange = getChange(`${basePath}.comment`);
        const setAddition = isAddition(basePath);

        exerciseData.sets.push({
          id: set.id,
          setNumber: set.setNumber,
          repetitions: set.repetitions,
          repetitionsChange: repetitionsChange ? {
            oldValue: formatValue(repetitionsChange.oldValue),
            newValue: formatValue(repetitionsChange.newValue),
          } : null,
          weight: set.weight,
          weightChange: weightChange ? {
            oldValue: formatValue(weightChange.oldValue),
            newValue: formatValue(weightChange.newValue),
          } : null,
          comment: set.comment,
          commentChange: commentChange ? {
            oldValue: formatValue(commentChange.oldValue),
            newValue: formatValue(commentChange.newValue),
          } : null,
          isAddition: setAddition,
        });
      });

      phaseData.exercises.push(exerciseData);
    });

    data.push(phaseData);
  });

  return data;
});

const routineChanges = computed(() => {
  if (!routineDiff.value) return {};

  return {
    name: getChange('routine.name'),
    noteForTrainee: getChange('routine.note_for_trainee'),
    noteForTrainer: getChange('routine.note_for_trainer'),
  };
});
</script>

<template>
  <BaseModal
    :open="props.isOpen"
    :title="`Cambios en ${props.routine.name}`"
    size="lg"
    @close="handleClose"
  >
    <div class="space-y-6">
      <div class="space-y-1">
        <div class="flex gap-1 text-xs">
          <span class="font-medium">Nombre:</span>
          <template v-if="routineChanges.name">
            <span class="rounded bg-red-200 px-1 text-gray-700 line-through">{{ routineChanges.name?.oldValue }}</span>
            <span class="rounded bg-green-200 px-1 text-gray-950">{{ routineChanges.name?.newValue }}</span>
          </template>
          <span v-else>
            {{ props.routine.name }}
          </span>
        </div>
        <div class="flex gap-1 text-xs">
          <span class="font-medium">Nota para el trainee:</span>
          <template v-if="routineChanges.noteForTrainee">
            <span class="rounded bg-red-200 px-1 text-gray-700 line-through">{{ routineChanges.noteForTrainee?.oldValue }}</span>
            <span class="rounded bg-green-200 px-1 text-gray-950">{{ routineChanges.noteForTrainee?.newValue }}</span>
          </template>
          <span v-else>
            {{ props.routine.noteForTrainee }}
          </span>
        </div>
        <div class="flex gap-1 text-xs">
          <span class="font-medium">Nota para el entrenador:</span>
          <template v-if="routineChanges.noteForTrainer">
            <span class="rounded bg-red-200 px-1 text-gray-700 line-through">{{ routineChanges.noteForTrainer?.oldValue }}</span>
            <span class="rounded bg-green-200 px-1 text-gray-950">{{ routineChanges.noteForTrainer?.newValue }}</span>
          </template>
          <span v-else>
            {{ props.routine.noteForTrainer }}
          </span>
        </div>
      </div>
      <div class="overflow-x-auto">
        <table class="w-full text-xs">
          <thead>
            <tr class="bg-gray-100">
              <th class="border-b px-3 py-2 text-left font-medium">
                Bloque
              </th>
              <th class="border-b px-3 py-2 text-left font-medium">
                Ejercicio
              </th>
              <th class="border-b px-3 py-2 text-center font-medium">
                Serie
              </th>
              <th class="border-b px-3 py-2 text-center font-medium">
                Duración
              </th>
              <th class="border-b px-3 py-2 text-left font-medium">
                Repeticiones
              </th>
              <th class="border-b px-3 py-2 text-left font-medium">
                Peso
              </th>
              <th class="border-b px-3 py-2 text-left font-medium">
                Comentario
              </th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <template
              v-for="phase in tableData"
              :key="`phase-${phase.id}`"
            >
              <template
                v-for="exercise in phase.exercises"
                :key="`exercise-${exercise.id}`"
              >
                <tr
                  v-for="set in exercise.sets"
                  :key="`set-${set.id}`"
                  :class="{
                    'bg-green-100': set.isAddition || exercise.isAddition || phase.isAddition,
                  }"
                >
                  <template v-if="exercise.sets.indexOf(set) === 0">
                    <td
                      v-if="phase.exercises.indexOf(exercise) === 0"
                      :rowspan="phase.rows"
                      class="border-x border-b px-3 py-2 align-top font-medium"
                      :class="{ 'bg-none': !phase.isAddition }"
                    >
                      <div class="flex items-center gap-1">
                        <div
                          v-if="phase.nameChange"
                          class="text-xs"
                        >
                          <span class="rounded-sm bg-red-200 px-1 py-px text-gray-700 line-through">{{ phase.nameChange?.oldValue }}</span>
                          <span class="ml-1 rounded-sm bg-green-200 px-1 py-px text-gray-950">{{ phase.nameChange?.newValue }}</span>
                        </div>
                        <div v-else>
                          {{ phase.name }}
                        </div>
                      </div>
                    </td>
                    <td
                      :rowspan="exercise.rows"
                      class="border-b border-r px-3 py-2 align-top font-medium"
                    >
                      <div class="flex items-center gap-1">
                        <div
                          v-if="exercise.nameChange"
                          class="text-xs"
                        >
                          <span class="rounded-sm bg-red-200 px-1 py-px text-gray-700 line-through">{{ exercise.nameChange?.oldValue }}</span>
                          <span class="ml-1 rounded-sm bg-green-200 px-1 py-px text-gray-950">{{ exercise.nameChange?.newValue }}</span>
                        </div>
                        <div v-else>
                          {{ exercise.name }}
                        </div>
                      </div>
                    </td>
                  </template>
                  <td class="border-b border-r px-3 py-2 text-center text-gray-400">
                    <span>{{ set.setNumber }}</span>
                  </td>
                  <td class="border-b border-r px-3 py-2">
                    <div
                      v-if="exercise.durationChange"
                      class="text-xs"
                    >
                      <span class="rounded-sm bg-red-200 px-1 py-px text-gray-700 line-through">{{ exercise.durationChange.oldValue }}</span>
                      <span class="ml-1 rounded-sm bg-green-200 px-1 py-px text-gray-950">{{ exercise.durationChange.newValue }}</span>
                    </div>
                    <div v-else>
                      {{ exercise.duration }}
                    </div>
                  </td>
                  <td class="border-b border-r px-3 py-2">
                    <div
                      v-if="set.repetitionsChange"
                      class="text-xs"
                    >
                      <span class="rounded-sm bg-red-200 px-1 py-px text-gray-700 line-through">{{ set.repetitionsChange.oldValue }}</span>
                      <span class="ml-1 rounded-sm bg-green-200 px-1 py-px text-gray-950">{{ set.repetitionsChange.newValue }}</span>
                    </div>
                    <div v-else>
                      {{ set.repetitions }}
                    </div>
                  </td>
                  <td class="border-b border-r px-3 py-2">
                    <div
                      v-if="set.weightChange"
                      class="text-xs"
                    >
                      <span class="rounded-sm bg-red-200 px-1 py-px text-gray-700 line-through">{{ set.weightChange.oldValue }}</span>
                      <span class="ml-1 rounded-sm bg-green-200 px-1 py-px text-gray-950">{{ set.weightChange.newValue }}</span>
                    </div>
                    <div v-else>
                      {{ set.weight }}
                    </div>
                  </td>
                  <td class="border-b border-r px-3 py-2">
                    <TextDiff
                      v-if="set.commentChange"
                      :old-text="set.commentChange.oldValue"
                      :new-text="set.commentChange.newValue"
                    />
                    <div v-else>
                      {{ set.comment }}
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mt-6 flex justify-end">
      <BaseButton
        variant="secondary"
        @click="handleClose"
      >
        Cerrar
      </BaseButton>
    </div>
  </BaseModal>
</template>
