<script setup lang="ts">
import { ArrowRight, Plus } from 'lucide-vue-next';
import { format } from 'date-fns';
import type { NutritionPlan } from '@/types';
import { useQuery } from '@tanstack/vue-query';
import { nutritionPlansApi } from '@/api';
import { BaseLink } from '@/components';

const props = defineProps<{
  nutritionPlans: NutritionPlan[];
}>();

const { data: nutritionPlans } = useQuery({
  queryKey: ['nutrition-plans'],
  queryFn: () => nutritionPlansApi.getAll(),
  initialData: props.nutritionPlans,
});
</script>

<template>
  <div class="container mx-auto px-4 py-8">
    <div class="mb-6 flex items-center justify-between">
      <h1 class="text-2xl font-semibold">
        Planes de nutrición
      </h1>
      <BaseLink
        href="/nutrition_plans/new"
      >
        <Plus class="size-4" />
        <span>
          Nuevo plan
        </span>
      </BaseLink>
    </div>

    <div class="overflow-hidden rounded-lg border border-gray-200 bg-white shadow">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-sm font-semibold text-gray-900"
            >
              Trainee
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-sm font-semibold text-gray-900"
            >
              Nutricionista
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-sm font-semibold text-gray-900"
            >
              Fecha de inicio
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-sm font-semibold text-gray-900"
            >
              Fecha de fin
            </th>
            <th
              scope="col"
              class="relative px-6 py-3"
            >
              <span class="sr-only">Ver</span>
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200">
          <tr
            v-for="plan in nutritionPlans"
            :key="plan.id"
            class="hover:bg-gray-50"
          >
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
              {{ plan.trainee?.fullName }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
              {{ plan.nutritionist?.fullName }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
              {{ format(new Date(plan.startAt), 'dd/MM/yyyy') }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
              {{ format(new Date(plan.endAt), 'dd/MM/yyyy') }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-right text-sm">
              <a
                :href="`/nutrition_plans/${plan.id}`"
                class="inline-flex items-center text-blue-600 hover:text-blue-900"
              >
                Ver
                <ArrowRight class="ml-1 size-4" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
