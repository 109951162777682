<script setup lang="ts">
import { computed } from 'vue';
import { diffChars } from 'diff';

const props = defineProps<{
  oldText: string;
  newText: string;
}>();

const diffParts = computed(() => diffChars(props.oldText, props.newText));

const oldTextParts = computed(() => diffParts.value.filter((part) => !part.added));

const newTextParts = computed(() => diffParts.value.filter((part) => !part.removed));
</script>

<template>
  <div
    v-if="oldTextParts.length > 0 || newTextParts.length > 0"
    class="grid grid-cols-2 gap-2 text-xs"
  >
    <div class="rounded-sm bg-red-100 px-1 py-px">
      <span
        v-for="(part, index) in oldTextParts"
        :key="index"
        :class="{
          'rounded-sm bg-red-300 py-px': part.removed,
        }"
      >{{ part.value }}</span>
    </div>
    <div class="rounded-sm bg-green-100 px-1 py-px">
      <span
        v-for="(part, index) in newTextParts"
        :key="index"
        :class="{
          'rounded-sm bg-green-300 py-px': part.added,
        }"
      >{{ part.value }}</span>
    </div>
  </div>
</template>
