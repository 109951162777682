<script setup lang="ts">
import { ref, computed, toRef } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { format } from 'date-fns';
import { AlertTriangle, Check } from 'lucide-vue-next';
import type { NutritionPlan, NutritionPlanAttributes, NamedResource, NutritionObjectiveAttributes } from '@/types';
import { nutritionPlansApi } from '@/api';
import { BackButton, BaseButton, BaseInput, BaseLabel, BaseSelect } from '@/components';
import NutritionObjectiveForm from './nutrition-objective-form.vue';

const props = defineProps<{
  nutritionPlan?: NutritionPlan;
  trainees: NamedResource[];
  nutritionists: NamedResource[];
}>();

const queryClient = useQueryClient();

const isEditing = computed(() => props.nutritionPlan !== undefined);

const nutritionPlan = toRef(() => props.nutritionPlan);

const form = ref<NutritionPlanAttributes>({
  traineeId: nutritionPlan.value?.traineeId,
  nutritionistId: nutritionPlan.value?.nutritionistId,
  startAt: format(new Date(nutritionPlan.value?.startAt ?? new Date()), "yyyy-MM-dd'T'HH:mm"),
  endAt: format(new Date(nutritionPlan.value?.endAt ?? new Date()), "yyyy-MM-dd'T'HH:mm"),
  externalUrl: nutritionPlan.value?.externalUrl,
  nutritionObjectivesAttributes: nutritionPlan.value?.nutritionObjectives.map(objective => ({
    id: objective.id,
    startAt: objective.startAt,
    description: objective.description,
    mealsAttributes: objective.meals.map(meal => ({
      id: meal.id,
      name: meal.name,
      recommendations: meal.recommendations,
    })),
  })) ?? [],
});

const filteredNutritionObjectives = computed(() => form.value.nutritionObjectivesAttributes?.filter(o => !o._destroy));

function addNutritionObjective() {
  form.value.nutritionObjectivesAttributes = [
    ...(form.value.nutritionObjectivesAttributes ?? []),
    {
      startAt: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
      description: '',
      mealsAttributes: [],
    },
  ];
}

function removeNutritionObjective(index: number) {
  const objective = form.value.nutritionObjectivesAttributes![index];
  if (objective.id) {
    form.value.nutritionObjectivesAttributes![index] = {
      ...objective,
      _destroy: true,
    };
  } else {
    form.value.nutritionObjectivesAttributes = form.value.nutritionObjectivesAttributes!.filter((_, i) => i !== index);
  }
}

function updateNutritionObjective(index: number, objective: NutritionObjectiveAttributes) {
  form.value.nutritionObjectivesAttributes![index] = objective;
}

const {
  isError,
  isPending,
  isIdle,
  isSuccess,
  mutate: saveNutritionPlan,
  error: saveNutritionPlanError,
} = useMutation({
  mutationFn: () => (props.nutritionPlan === undefined ?
    nutritionPlansApi.create(form.value) :
    nutritionPlansApi.update(props.nutritionPlan.id, form.value)),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['nutrition-plans'] });
  },
});

function onSubmit() {
  saveNutritionPlan();
}

function handleBack() {
  history.back();
}
</script>

<template>
  <div class="container mx-auto px-4 py-8">
    <div class="mb-6 flex flex-col gap-4">
      <BackButton @click="handleBack" />
      <h1 class="text-2xl font-semibold">
        {{ isEditing ? 'Editar' : 'Crear' }} plan de nutrición
      </h1>
    </div>

    <form
      v-if="isIdle"
      class="space-y-8"
      @submit.prevent="onSubmit"
    >
      <!-- Basic information -->
      <div class="overflow-hidden rounded-lg border border-gray-200 bg-white shadow">
        <div class="px-4 py-5 sm:p-6">
          <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
            <div class="flex flex-col gap-1">
              <BaseLabel
                label="Trainee"
                name="traineeId"
                required
              />
              <BaseSelect
                v-model="form.traineeId"
                name="traineeId"
                :options="trainees"
              />
            </div>
            <div class="flex flex-col gap-1">
              <BaseLabel
                label="Nutricionista"
                name="nutritionistId"
                required
              />
              <BaseSelect
                v-model="form.nutritionistId"
                name="nutritionistId"
                :options="nutritionists"
              />
            </div>
            <div class="flex flex-col gap-1">
              <BaseLabel
                label="Fecha de inicio"
                name="startAt"
                required
              />
              <BaseInput
                v-model="form.startAt"
                name="startAt"
                type="datetime-local"
              />
            </div>
            <div class="flex flex-col gap-1">
              <BaseLabel
                label="Fecha de fin"
                name="endAt"
                required
              />
              <BaseInput
                v-model="form.endAt"
                name="endAt"
                type="datetime-local"
              />
            </div>
            <div class="sm:col-span-2">
              <BaseLabel
                label="URL externa"
                name="externalUrl"
              />
              <BaseInput
                v-model="form.externalUrl"
                name="externalUrl"
                type="text"
                placeholder="https://www.notion.so/gokapso/plan-de-alimentacion"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Nutrition objectives -->
      <div class="space-y-4">
        <div class="flex items-center justify-between">
          <h2 class="text-lg font-medium text-gray-900">
            Objetivos nutricionales
          </h2>
          <BaseButton
            type="button"
            variant="secondary"
            @click="addNutritionObjective"
          >
            Agregar objetivo
          </BaseButton>
        </div>

        <NutritionObjectiveForm
          v-for="(_, objectiveIndex) in filteredNutritionObjectives"
          :key="objectiveIndex"
          v-model="form.nutritionObjectivesAttributes[objectiveIndex]"
          :index="objectiveIndex"
          @update="updateNutritionObjective(objectiveIndex, $event)"
          @remove="removeNutritionObjective(objectiveIndex)"
        />
      </div>

      <!-- Submit button -->
      <div class="flex justify-end">
        <BaseButton
          type="submit"
          :disabled="isPending"
        >
          {{ isEditing ? 'Guardar cambios' : 'Crear plan' }}
        </BaseButton>
      </div>
    </form>

    <div
      v-if="isError"
      class="mt-4 rounded-md bg-red-50 p-4"
    >
      <div class="flex">
        <div class="shrink-0">
          <AlertTriangle class="text-red-400" />
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-red-800">
            Error al {{ isEditing ? 'actualizar' : 'crear' }} el plan
          </h3>
          <div class="mt-2 text-sm text-red-700">
            <pre>{{ saveNutritionPlanError }}</pre>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isSuccess"
      class="mt-4 rounded-md bg-green-50 p-4"
    >
      <div class="flex">
        <div class="shrink-0">
          <Check class="text-green-400" />
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-green-800">
            Plan {{ isEditing ? 'actualizado' : 'creado' }} exitosamente
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>
