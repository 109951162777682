import type { NutritionPlan, NutritionPlanAttributes } from '@/types';
import { api } from './fetch-wrapper';

export const nutritionPlansApi = {
  async getAll(): Promise<NutritionPlan[]> {
    const response = await api.get({ url: '/api/internal/nutrition_plans' });

    return response as NutritionPlan[];
  },

  async get(id: number): Promise<NutritionPlan> {
    const response = await api.get({ url: `/api/internal/nutrition_plans/${id}` });

    return response as NutritionPlan;
  },

  async create(attributes: Partial<NutritionPlanAttributes>): Promise<NutritionPlan> {
    const response = await api.post({
      url: '/api/internal/nutrition_plans',
      body: { nutritionPlan: attributes },
    });

    return response as NutritionPlan;
  },

  async update(id: number, attributes: Partial<NutritionPlanAttributes>): Promise<NutritionPlan> {
    const response = await api.patch({
      url: `/api/internal/nutrition_plans/${id}`,
      body: { nutritionPlan: attributes },
    });

    return response as NutritionPlan;
  },
};
