import type { WorkoutPlan } from '@/types/extended';
import { api } from './axios-wrapper';

export const workoutPlansApi = {
  async getAll() {
    const path = '/api/internal/workout_plans';

    const response = await api({
      method: 'get',
      url: path,
    });

    return response.data as WorkoutPlan[];
  },
};
