import { serialize } from 'object-to-formdata';
import type { RoutineAttributes } from '@/types';
import type { Routine } from '@/types/extended';
import { api } from './axios-wrapper';

export const routinesApi = {
  async update(routineId: number, routineAttributes: Partial<RoutineAttributes>) {
    const path = `/api/internal/routines/${routineId}`;
    const formData = serialize({ routine: routineAttributes }, { indices: true });

    const response = await api({
      method: 'put',
      url: path,
      data: formData,
    });

    return response.data as Routine;
  },

  async create(routineAttributes: RoutineAttributes) {
    const path = '/api/internal/routines';
    const formData = serialize({ routine: routineAttributes }, { indices: true });

    const response = await api({
      method: 'post',
      url: path,
      data: formData,
    });

    return response.data as Routine;
  },

  async get(routineId: number) {
    const path = `/api/internal/routines/${routineId}`;

    const response = await api({
      method: 'get',
      url: path,
    });

    return response.data as Routine;
  },

  async getAll(params: Record<string, unknown> = {}) {
    const path = '/api/internal/routines';

    const response = await api({
      method: 'get',
      url: path,
      params,
    });

    return response.data as Routine[];
  },

  async clone({
    routineId,
    scheduledAt,
    workoutPlanId,
    withAudios,
  }: {
    routineId: number;
    scheduledAt: string;
    workoutPlanId: number;
    withAudios: boolean;
  }) {
    const path = `/api/internal/routines/${routineId}/clone`;

    const response = await api({
      method: 'post',
      url: path,
      data: { scheduledAt, workoutPlanId, withAudios },
    });

    return response.data as Routine;
  },

  async destroy(routineId: number) {
    const path = `/api/internal/routines/${routineId}`;

    await api({
      method: 'delete',
      url: path,
    });
  },

  async progress(routine: Routine) {
    const path = `/api/internal/routines/${routine.id}/progress`;

    const response = await api({
      method: 'get',
      url: path,
    });

    return response.data as RoutineAttributes;
  },
};
