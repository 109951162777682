<script setup lang="ts">
import { computed, ref, toRef } from 'vue';
import { format, differenceInMinutes } from 'date-fns';
import { es } from 'date-fns/locale';
import { TabGroup, TabList, Tab } from '@headlessui/vue';
import { useQuery } from '@tanstack/vue-query';
import { TriangleAlert } from 'lucide-vue-next';
import type { Trainee } from '@/types';
import type { Routine, Workout } from '@/types/extended';
import { exerciseExecutionsApi } from '@/api';
import WorkoutDetailsExerciseSet from './workout-details-exercise-set.vue';
import WorkoutCompare from './workout-compare.vue';
import WorkoutDetailsPhaseExecutions from './workout-details-phase-executions.vue'; // Import the new component

const props = defineProps<{
  routine?: Routine;
  workout: Workout;
  trainee: Trainee;
}>();

const workout = toRef(props.workout);

const formattedStartDate = computed(() => {
  if (workout.value.startedAt) {
    return format(new Date(workout.value.startedAt), "d 'de' MMMM 'a las' HH:mm", { locale: es });
  }

  return 'Fecha no disponible';
});

const workoutDuration = computed(() => {
  if (workout.value.startedAt && workout.value.endedAt) {
    const durationInMinutes = differenceInMinutes(new Date(workout.value.endedAt), new Date(workout.value.startedAt));

    return `${durationInMinutes} minutos`;
  }

  return '-';
});

const tabs = ref(['Series', 'Ejecuciones']);
const selectedTab = ref(tabs.value[0]);

const exerciseIds = computed(() => {
  if (props.routine) {
    return props.routine.routinePhases.flatMap(phase =>
      phase.routinePhaseExercises.map(exercise => exercise.exerciseId),
    );
  }

  return [];
});

const { data: exerciseExecutions, refetch: refetchExerciseExecutions } = useQuery({
  queryKey: ['exerciseExecutions', workout.value.traineeId],
  queryFn: () => exerciseExecutionsApi.getAll({ traineeId: workout.value.traineeId, exerciseIds: exerciseIds.value }),
  enabled: false,
});

function handleTabChange(index: number) {
  selectedTab.value = tabs.value[index];
  if (selectedTab.value === 'Ejecuciones') {
    refetchExerciseExecutions();
  }
}

function getExerciseExecutions(exerciseId: number) {
  return exerciseExecutions.value?.filter(exerciseExecution => exerciseExecution.exerciseId === exerciseId);
}

function getWorkoutPhase(phaseId: number) {
  return workout.value.workoutPhases.find(workoutPhase => workoutPhase.routinePhaseId === phaseId);
}

const tableData = computed(() => {
  if (!props.routine) return [];

  return props.routine.routinePhases.map(phase => ({
    id: phase.id,
    name: phase.name,
    exercises: phase.routinePhaseExercises.map(exercise => ({
      name: exercise.exerciseName,
      routinePhaseExercise: exercise,
      sets: exercise.routineExerciseSets,
      exerciseExecutions: getExerciseExecutions(exercise.exerciseId),
      isReplaced: exercise.exerciseId !== exercise.plannedExerciseId,
    })),
    totalSets: phase.routinePhaseExercises.reduce((total, exercise) => total + exercise.routineExerciseSets.length, 0),
  }));
});
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="flex items-center justify-between">
      <h2 class="text-2xl font-bold">
        {{ trainee.fullName }} - {{ workout.name }}
      </h2>
    </div>

    <div>
      <div class="mb-4 space-y-2">
        <p><strong>Inicio:</strong> {{ formattedStartDate }}</p>
        <p><strong>Duración:</strong> {{ workoutDuration }}</p>
        <p><strong>RPE:</strong> {{ workout.perceivedExertion ? `${workout.perceivedExertion}/10` : '-' }}</p>
        <p><strong>Comentario:</strong> {{ workout.comment || '-' }}</p>
      </div>

      <template v-if="routine">
        <div class="flex gap-4">
          <div class="w-3/4 rounded-lg bg-gray-100">
            <TabGroup @change="handleTabChange">
              <TabList class="flex h-12 items-center gap-1 rounded-md bg-gray-100 p-1">
                <Tab
                  v-for="tab in tabs"
                  :key="tab"
                  v-slot="{ selected }"
                  as="template"
                >
                  <button
                    class="w-full rounded-md px-4 py-2 text-sm font-medium transition-colors"
                    :class="selected ? 'bg-white shadow' : 'text-gray-700 hover:bg-gray-200'"
                  >
                    {{ tab }}
                  </button>
                </Tab>
              </TabList>
            </TabGroup>
            <div class="overflow-x-auto">
              <template v-if="selectedTab === 'Series'">
                <table class="w-full text-xs">
                  <thead>
                    <tr class="bg-gray-100">
                      <th class="border-b px-3 py-2 text-left font-medium">
                        Fase
                      </th>
                      <th class="border-b px-3 py-2 text-left font-medium">
                        Ejercicio
                      </th>
                      <th class="border-b px-3 py-2 text-center font-medium">
                        Serie
                      </th>
                      <th class="border-b px-3 py-2 text-left font-medium">
                        Reps
                      </th>
                      <th class="border-b px-3 py-2 text-left font-medium">
                        Peso
                      </th>
                      <th class="border-b px-3 py-2 text-left font-medium">
                        Esfuerzo
                      </th>
                      <th class="border-b px-3 py-2 text-center font-medium">
                        Completado
                      </th>
                      <th class="border-b px-3 py-2 text-left font-medium">
                        Comentario
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white">
                    <template
                      v-for="phase in tableData"
                      :key="`phase-${phase.id}`"
                    >
                      <template
                        v-for="(exercise, exerciseIndex) in phase.exercises"
                        :key="`exercise-${exercise.routinePhaseExercise.id}`"
                      >
                        <tr
                          v-for="(set, setIndex) in exercise.sets"
                          :key="`set-${set.id}`"
                        >
                          <template v-if="exerciseIndex === 0 && setIndex === 0">
                            <td
                              :rowspan="phase.totalSets"
                              class="border-x border-b px-3 py-2 align-top font-medium"
                            >
                              {{ phase.name }}
                            </td>
                          </template>
                          <template v-if="setIndex === 0">
                            <td
                              :rowspan="exercise.sets.length"
                              class="border-b border-r px-3 py-2 align-top font-medium"
                            >
                              <div class="flex flex-col gap-2">
                                {{ exercise.name }}
                                <div
                                  v-if="exercise.isReplaced"
                                  class="flex size-min items-center gap-2 rounded-md bg-yellow-100 px-2 py-1 text-xs text-yellow-800"
                                >
                                  <TriangleAlert class="size-3 shrink-0 text-yellow-500" />
                                  <span class="whitespace-nowrap">
                                    Ejercicio reemplazado
                                  </span>
                                </div>
                              </div>
                            </td>
                          </template>
                          <WorkoutDetailsExerciseSet
                            :workout-phase="getWorkoutPhase(phase.id)"
                            :routine-phase-exercise="exercise.routinePhaseExercise"
                            :routine-exercise-set="set"
                          />
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </template>
              <template v-else-if="selectedTab === 'Ejecuciones'">
                <table class="w-full text-xs">
                  <thead>
                    <tr class="bg-gray-100">
                      <th class="border-b px-3 py-2 text-left font-medium">
                        Fase
                      </th>
                      <th class="border-b px-3 py-2 text-left font-medium">
                        Ejercicio
                      </th>
                      <th class="border-b px-3 py-2 text-left font-medium">
                        Fecha
                      </th>
                      <th class="border-b px-3 py-2 text-left font-medium">
                        1RM estimado
                      </th>
                      <th class="border-b px-3 py-2 text-left font-medium">
                        Reps
                      </th>
                      <th class="border-b px-3 py-2 text-left font-medium">
                        Series
                      </th>
                      <th class="border-b px-3 py-2 text-left font-medium">
                        Peso máximo
                      </th>
                      <th class="border-b px-3 py-2 text-left font-medium">
                        Volumen
                      </th>
                      <th class="border-b px-3 py-2 text-left font-medium">
                        Descanso series
                      </th>
                      <th class="border-b px-3 py-2 text-left font-medium">
                        Promedio esfuerzo
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white">
                    <template
                      v-for="phase in tableData"
                      :key="`phase-${phase.id}`"
                    >
                      <WorkoutDetailsPhaseExecutions
                        :phase-data="phase"
                        :workout-phase="getWorkoutPhase(phase.id)"
                      />
                    </template>
                  </tbody>
                </table>
              </template>
            </div>
          </div>
          <WorkoutCompare
            :current-workout="workout"
            :trainee="trainee"
            :routine="routine"
            class="w-1/4"
          />
        </div>
      </template>
    </div>
  </div>
</template>
