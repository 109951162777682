import { api } from '@/api/axios-wrapper';
import type { TraineeAttributes } from '@/types';
import type { Trainee } from '@/types/extended';

export const traineesApi = {
  async update(id: number, attributes: Partial<TraineeAttributes>) {
    const response = await api({
      method: 'put',
      url: `/api/internal/trainees/${id}`,
      data: { trainee: attributes },
    });

    return response.data as Trainee;
  },

  async get(id: number) {
    const response = await api({
      method: 'get',
      url: `/api/internal/trainees/${id}`,
    });

    return response.data as Trainee;
  },
};
