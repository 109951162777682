import { onMounted, onUnmounted } from 'vue';

interface TallyWindow extends Window {
  Tally?: {
    loadEmbeds: () => void;
  }
}

declare const window: TallyWindow;

export interface TallyMessageHandler {
  (event: MessageEvent): void;
}

export function useTallyForm(messageHandler: TallyMessageHandler) {
  // eslint-disable-next-line max-statements
  function initializeTally() {
    const widgetScriptSrc = 'https://tally.so/widgets/embed.js';

    function load() {
      if (window.Tally !== undefined) {
        window.Tally.loadEmbeds();

        return;
      }

      document
        .querySelectorAll<HTMLIFrameElement>('iframe[data-tally-src]:not([src])')
        .forEach((iframeEl) => {
          if (iframeEl.dataset.tallySrc) {
            iframeEl.src = iframeEl.dataset.tallySrc;
          }
        });
    }

    if (window.Tally !== undefined) {
      load();

      return;
    }

    if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
      const script = document.createElement('script');
      script.src = widgetScriptSrc;
      script.onload = load;
      script.onerror = load;
      document.body.appendChild(script);

      return;
    }
  }

  onMounted(() => {
    window.addEventListener('message', messageHandler);
    initializeTally();
  });

  onUnmounted(() => {
    window.removeEventListener('message', messageHandler);
  });
}
