import { serialize } from 'object-to-formdata';
import type { RoutinePhaseAttributes } from '@/types';
import type { RoutinePhase } from '@/types/extended';
import { api } from './axios-wrapper';

export const routinePhasesApi = {
  async get(phaseId: number) {
    const path = `/api/internal/routine_phases/${phaseId}`;

    const response = await api({
      method: 'get',
      url: path,
    });

    return response.data as RoutinePhase;
  },

  async create(phaseAttributes: Partial<RoutinePhaseAttributes>) {
    const path = '/api/internal/routine_phases';
    const formData = serialize({ routinePhase: phaseAttributes }, { indices: true });

    const response = await api({
      method: 'post',
      url: path,
      data: formData,
    });

    return response.data as RoutinePhase;
  },

  async update(phaseId: number, phaseAttributes: Partial<RoutinePhaseAttributes>) {
    const path = `/api/internal/routine_phases/${phaseId}`;
    const formData = serialize({ routinePhase: phaseAttributes }, { indices: true });

    const response = await api({
      method: 'put',
      url: path,
      data: formData,
    });

    return response.data as RoutinePhase;
  },
};
