<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { Ear, EarOff } from 'lucide-vue-next';
import { AudioRecorder, BaseButton } from '@/components';

interface Props {
  audioUrl?: string;
}

const audio = defineModel<File | undefined>('audio');

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'update', file: File | undefined): void;
}>();

const audioUrl = computed(() => {
  if (audio.value instanceof File) {
    return URL.createObjectURL(audio.value);
  }

  return props.audioUrl;
});

function handleAudioChange(audioBlob: Blob) {
  const file = new File([audioBlob], 'audio.wav', { type: 'audio/wav' });
  audio.value = file;
  emit('update', file);
}

function handleFileInputChange(event: Event) {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files[0]) {
    audio.value = target.files[0];
    emit('update', target.files[0]);
  }
}

const showOnRight = ref(false);

function updatePopoverPosition(event: MouseEvent) {
  const screenWidth = window.innerWidth;
  // eslint-disable-next-line no-magic-numbers
  showOnRight.value = event.clientX < screenWidth / 2;
}

watch(
  () => props.audioUrl,
  (newAudioUrl: string | undefined) => {
    if (newAudioUrl) {
      audio.value = undefined;
    }
  },
  { immediate: true },
);
</script>

<template>
  <Popover class="relative w-min">
    <PopoverButton
      as="div"
      class="rounded-md border border-slate-200"
      @click="updatePopoverPosition"
    >
      <BaseButton
        size="sm"
        variant="outline"
      >
        <Ear
          v-if="audioUrl"
          class="size-4"
        />
        <EarOff
          v-else
          class="size-4"
        />
      </BaseButton>
    </PopoverButton>

    <PopoverPanel
      :class="{
        'left-0': showOnRight,
        'right-0': !showOnRight
      }"
      class="absolute top-9 z-10 w-72 rounded-md bg-white p-4 shadow-lg ring-1 ring-black/5"
    >
      <div class="flex flex-col gap-4">
        <div class="h-8 w-full">
          <audio
            v-if="audioUrl"
            :src="audioUrl"
            controls
            class="size-full"
          />
          <div
            v-else
            class="flex size-full items-center justify-center rounded bg-gray-100 text-xs text-gray-400"
          >
            Sin audio
          </div>
        </div>
        <div class="flex w-full items-center justify-between gap-2">
          <AudioRecorder
            @update="handleAudioChange"
          />
          <input
            type="file"
            accept="audio/*"
            class="text-xs"
            @change="handleFileInputChange"
          >
        </div>
      </div>
    </PopoverPanel>
  </Popover>
</template>
