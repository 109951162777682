<script setup lang="ts">
import { ref, computed, toRaw } from 'vue';
import type { RoutinePhaseAttributes } from '@/types';
import type { RoutinePhase } from '@/types/extended';
import {
  BaseButton,
  RoutineFormPhasesExercises,
  RoutinePhaseForm,
} from '@/components';
import {
  ChevronDown,
  ChevronUp,
  Trash2,
  GripVertical,
  Pencil,
  ClipboardCheck,
  ClipboardCopy,
} from 'lucide-vue-next';
import { StorageSerializers, useStorage } from '@vueuse/core';
import { deepOmit } from '@/utils/deep-omit';

interface Props {
  phaseAttributes: RoutinePhaseAttributes;
  index: number;
  isExpanded: boolean;
  routinePhases: RoutinePhase[]
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'remove', index: number): void;
  (e: 'toggleExpand', index: number): void;
  (e: 'update', phase: RoutinePhaseAttributes, index: number): void;
  (e: 'mouseDown', event: MouseEvent): void;
  (e: 'mouseUp', event: MouseEvent): void;
}>();

const editingPhase = ref(false);
const isCopied = ref(false);
const COPIED_TIMEOUT = 2000;

const exerciseCount = computed(() => props.phaseAttributes.routinePhaseExercisesAttributes?.length || 0);
const setCount = computed(() => props.phaseAttributes.sets || 0);

const routinePhaseClipboard = useStorage<RoutinePhaseAttributes | null>(
  'routine-phase-clipboard',
  null,
  sessionStorage,
  { serializer: StorageSerializers.object },
);

function handleCopy() {
  routinePhaseClipboard.value = deepOmit(
    structuredClone(toRaw(props.phaseAttributes)),
    ['id', 'routineId'],
  ) as RoutinePhaseAttributes;
  isCopied.value = true;
  setTimeout(() => {
    isCopied.value = false;
  }, COPIED_TIMEOUT);
}

function editPhase() {
  editingPhase.value = true;
}

function closePhaseForm() {
  editingPhase.value = false;
}

function updatePhase(phase: RoutinePhaseAttributes) {
  emit('update', phase, props.index);
  closePhaseForm();
}
</script>

<template>
  <div class="group/phase flex w-full flex-col rounded-md py-1 pl-1">
    <RoutinePhaseForm
      v-if="editingPhase"
      :phase-id="phaseAttributes.id"
      :phases="routinePhases"
      :phase-attributes="phaseAttributes"
      :open="editingPhase"
      @update="updatePhase"
      @close="closePhaseForm"
    />
    <div
      v-else
      class="flex items-center justify-between"
    >
      <div class="flex items-center gap-1">
        <div
          class="cursor-move p-1"
          @mousedown="$emit('mouseDown', $event)"
          @mouseup="$emit('mouseUp', $event)"
        >
          <GripVertical class="size-4 shrink-0" />
        </div>
        <h5 class="font-semibold">
          {{ phaseAttributes.name || 'Bloque sin nombre' }}
        </h5>
      </div>
      <div class="flex items-center">
        <BaseButton
          type="button"
          variant="ghost"
          size="sm"
          @click="$emit('remove', index)"
        >
          <Trash2 class="hidden size-4 group-hover/phase:inline-flex" />
        </BaseButton>
        <BaseButton
          variant="ghost"
          size="sm"
          @click="handleCopy"
        >
          <ClipboardCheck
            v-if="isCopied"
            class="hidden size-4 group-hover/phase:inline-flex"
          />
          <ClipboardCopy
            v-else
            class="hidden size-4 group-hover/phase:inline-flex"
          />
        </BaseButton>
        <BaseButton
          type="button"
          variant="ghost"
          size="sm"
          @click="editPhase"
        >
          <Pencil class="hidden size-4 group-hover/phase:inline-flex" />
        </BaseButton>
        <BaseButton
          v-if="phaseAttributes.id"
          type="button"
          variant="ghost"
          size="sm"
          @click="$emit('toggleExpand', index)"
        >
          <component
            :is="isExpanded ? ChevronUp : ChevronDown"
            class="size-4"
          />
        </BaseButton>
        <div
          v-else
          class="h-8 w-10"
        />
      </div>
    </div>
    <div v-if="isExpanded">
      <RoutineFormPhasesExercises
        v-if="phaseAttributes.id"
        :phase-id="phaseAttributes.id"
        :phases="routinePhases"
      />
    </div>
    <span
      v-else
      class="flex w-full items-center justify-center gap-1 text-sm text-gray-500"
    >
      <span>
        {{ exerciseCount }}
        {{ exerciseCount === 1 ? 'ejercicio' : 'ejercicios' }}
      </span>
      <span>
        - {{ setCount }}
        {{ setCount === 1 ? 'serie' : 'series' }}
      </span>
    </span>
  </div>
</template>
