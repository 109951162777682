type Primitive = string | number | boolean | symbol | undefined | null

type DeepOmit<T, K> = T extends Primitive ? T : {
  [P in Exclude<keyof T, K>]:
    T[P] extends infer TP ?
      TP extends Primitive ? TP :
      TP extends Array<unknown> ? Array<DeepOmit<TP[number], K>> :
      DeepOmit<TP, K>
    : never
}

function deepOmit<T extends object, K extends string>(obj: T, keys: K[]): DeepOmit<T, K> {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (keys.includes(key as K)) {
      return acc;
    }

    if (Array.isArray(value)) {
      return { ...acc, [key]: value.map(item => deepOmit(item, keys)) };
    }

    if (typeof value === 'object' && value !== null) {
      return { ...acc, [key]: deepOmit(value, keys) };
    }

    return { ...acc, [key]: value };
  }, {} as DeepOmit<T, K>);
}

export { deepOmit };
