import type { TallySubmissionPayload, FollowUpMeetingSurvey } from '@/types';
import { api } from './axios-wrapper';

export const followUpMeetingSurveysApi = {
  async create(traineeId: number, submission: Partial<TallySubmissionPayload>) {
    const path = '/api/internal/follow_up_meeting_surveys';

    const response = await api({
      method: 'post',
      url: path,
      data: { followUpMeetingSurvey: { ...submission, traineeId } },
    });

    return response.data as FollowUpMeetingSurvey;
  },
};
