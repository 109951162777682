import type { ExerciseExecution } from '@/types';
import { api } from './axios-wrapper';

export const exerciseExecutionsApi = {
  async getAll({ traineeId, exerciseIds }: { traineeId: number, exerciseIds?: number[] }) {
    const path = '/api/internal/exercise_executions';

    const response = await api({
      method: 'get',
      url: path,
      params: { q: { 'trainee_id_eq': traineeId, s: 'execution_date desc', 'exercise_id_in': exerciseIds } },
    });

    return response.data as ExerciseExecution[];
  },
};
