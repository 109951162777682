<script setup lang="ts">
import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { exercisesApi, exerciseExecutionsApi } from '@/api';
import BaseModal from './base-modal.vue';
import ExerciseVideo from './exercise-video.vue';
import ExerciseExecutionDetails from './exercise-execution-details.vue';

const props = defineProps<{
  exerciseId: number;
  traineeId: number;
}>();

const emit = defineEmits<{
  close: [];
}>();

const { data: exercise } = useQuery({
  queryKey: ['exercise', props.exerciseId],
  queryFn: () => exercisesApi.get(props.exerciseId),
});

const muscleGroupNames = computed(() => {
  if (!exercise.value) return '';

  return exercise.value.muscleGroups.map((muscleGroup) => muscleGroup.name).join(', ');
});

const { data: exerciseExecutions } = useQuery({
  queryKey: ['exerciseExecutions', props.traineeId, props.exerciseId],
  queryFn: () => exerciseExecutionsApi.getAll({ traineeId: props.traineeId, exerciseIds: [props.exerciseId] }),
  initialData: [],
});

</script>

<template>
  <BaseModal
    :open="true"
    :title="exercise?.name || ''"
    @close="emit('close')"
  >
    <div class="flex flex-col gap-6">
      <div
        v-if="exercise"
        class="flex flex-col gap-4"
      >
        <div class="flex flex-col gap-1 text-sm">
          <p>
            <span class="font-semibold">Grupos musculares:</span> {{ muscleGroupNames }}
          </p>
          <p>
            <span class="font-semibold">Equipamiento:</span> {{ exercise.equipment?.name || 'Ninguno' }}
          </p>
          <div>
            <p class="font-semibold">
              Instrucciones:
            </p>
            <p
              v-for="instruction in exercise.exerciseInstructions"
              :key="instruction.id"
            >
              {{ instruction.description }}
            </p>
          </div>
        </div>
        <ExerciseVideo :exercise="exercise" />
      </div>

      <ExerciseExecutionDetails
        :exercise-executions="exerciseExecutions"
      />
    </div>
  </BaseModal>
</template>
