<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { format, subDays } from 'date-fns';
import { daysInWeek } from 'date-fns/constants';
import type { Trainee } from '@/types';
import type { Routine, Workout } from '@/types/extended';
import { BaseButton, BaseCombobox } from '@/components';
import { Copy } from 'lucide-vue-next';
import { useStreamRequest } from '@/composables/stream-request';
import { workoutsApi } from '@/api';

const props = defineProps<{
  currentWorkout: Workout;
  trainee: Trainee;
  routine: Routine;
}>();

const selectedWorkoutId = ref<number | undefined>(undefined);
const isCopied = ref(false);

const workoutsQueryParams = ref({
  'routine_workout_plan_id_eq': props.routine.workoutPlanId,
  'ended_at_not_null': true,
  'routine_id_not_null': true,
  s: 'ended_at desc',
});

const { data: workouts } = useQuery({
  queryKey: ['workouts', workoutsQueryParams],
  queryFn: () => workoutsApi.getAll({ filters: workoutsQueryParams.value }),
  initialData: [],
});

const { data: selectedWorkout, refetch: refetchSelectedWorkout } = useQuery({
  queryKey: ['workout', selectedWorkoutId.value],
  queryFn: () => workoutsApi.get(selectedWorkoutId.value as number),
  enabled: false,
});

function findDefaultWorkout() {
  let defaultWorkout;

  if (props.routine.originalId) {
    defaultWorkout = workouts.value.find(workout => workout.routineId === props.routine.originalId);
  } else {
    const targetDate = subDays(new Date(props.currentWorkout.endedAt as string), daysInWeek);
    defaultWorkout = workouts.value.find(
      workout => new Date(workout.endedAt as string).toDateString() === targetDate.toDateString(),
    );
  }

  if (defaultWorkout) {
    selectedWorkoutId.value = defaultWorkout.id;
    refetchSelectedWorkout();
  }
}

watch(workouts, () => {
  if (workouts.value.length > 0 && !selectedWorkoutId.value) {
    findDefaultWorkout();
  }
});

const workoutOptions = computed(() =>
  workouts.value
    .filter((workout: Workout) => workout.routineId && workout.endedAt && workout.id !== props.currentWorkout.id)
    .map((workout: Workout) => ({
      id: workout.id,
      name: `${workout.name} - ${format(workout.endedAt as string, 'dd/MM/yyyy')}`,
    })),
);

function handleSelectedWorkoutIdChange(id: number | string) {
  selectedWorkoutId.value = Number(id);
  refetchSelectedWorkout();
}

const comparisonBody = computed(() => ({
  previousWorkout: selectedWorkout.value,
  currentWorkout: props.currentWorkout,
}));

const { result: comparisonResult, isLoading, makeRequest: compareWorkouts } = useStreamRequest({
  url: 'https://kapso-ai.fly.dev/api/workouts/compare',
  body: comparisonBody,
});

const IS_COPIED_TIMEOUT = 2000;

function copyToClipboard() {
  navigator.clipboard.writeText(comparisonResult.value).then(() => {
    isCopied.value = true;
    setTimeout(() => {
      isCopied.value = false;
    }, IS_COPIED_TIMEOUT);
  });
}

const isDisabled = computed(
  () => !selectedWorkoutId.value || (selectedWorkoutId.value !== selectedWorkout.value?.id) || isLoading.value,
);
</script>

<template>
  <div class="flex flex-col gap-2">
    <h3 class="text-lg font-semibold">
      Comparar
    </h3>
    <BaseCombobox
      :model-value="selectedWorkoutId"
      name="selectedWorkoutId"
      :options="workoutOptions"
      class="text-sm"
      @update:model-value="handleSelectedWorkoutIdChange"
    />
    <BaseButton
      v-if="comparisonResult"
      class="mt-2 flex w-full items-center justify-center gap-2 text-sm"
      @click="copyToClipboard"
    >
      <Copy class="size-4" />
      {{ isCopied ? 'Copiado!' : 'Copiar resultado' }}
    </BaseButton>
    <BaseButton
      v-else
      :disabled="isDisabled"
      class="w-full text-sm"
      @click="compareWorkouts"
    >
      {{ isLoading ? 'Comparando...' : 'Comparar' }}
    </BaseButton>
    <div
      v-if="comparisonResult"
      class="rounded border p-2"
    >
      <pre class="whitespace-pre-wrap">{{ comparisonResult }}</pre>
    </div>
  </div>
</template>
