import type { Workout } from '@/types/extended';
import { api } from './axios-wrapper';

export const workoutsApi = {
  async getAll({ filters }: { filters: Record<string, unknown> }) {
    const path = '/api/internal/workouts';

    const response = await api({
      method: 'get',
      url: path,
      params: {
        q: {
          ...filters,
        },
      },
    });

    return response.data as Workout[];
  },

  async get(workoutId: number) {
    const path = `/api/internal/workouts/${workoutId}`;

    const response = await api({
      method: 'get',
      url: path,
    });

    return response.data as Workout;
  },
};
