import { api } from './axios-wrapper';

const chatTokensApi = {
  async create({ userId }: { userId: string }): Promise<string> {
    const response = await api.post('/api/internal/chat_tokens', {
      chatToken: { userId },
    });

    return response.data as string;
  },
};

export { chatTokensApi };
