/* eslint-disable max-statements */
import { ref, toValue, type Ref } from 'vue';
import { api } from '@/api/fetch-wrapper';

export function useStreamRequest(
  { url, body }: { url: string | Ref<string>, body: Record<string, unknown> | Ref<Record<string, unknown>> },
) {
  const result = ref<string>('');
  const isLoading = ref(false);
  const error = ref<string>('');

  async function processStreamResponse(reader: ReadableStreamDefaultReader<Uint8Array>) {
    const decoder = new TextDecoder();

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { value, done } = await reader.read();
      if (done) break;

      const chunk = decoder.decode(value, { stream: true });
      const lines = chunk.split('\n');

      const SLICE_AT_INDEX = 5;

      for (const line of lines) {
        if (line.startsWith('data:')) {
          const jsonString = line.slice(SLICE_AT_INDEX).trim();
          const jsonData = JSON.parse(jsonString);
          if (jsonData.content) {
            result.value += jsonData.content;
          }
        }
      }
    }
  }

  async function makeRequest() {
    isLoading.value = true;
    result.value = '';

    try {
      const response = await api.post({
        url: toValue(url),
        headers: {
          'Accept': 'text/event-stream',
          'Content-Type': 'application/json',
        },
        body: toValue(body),
      });

      if (!(response instanceof Response)) {
        throw new Error('Unexpected response type');
      }

      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error('Unable to read the response');
      }

      await processStreamResponse(reader);
    } catch (e: unknown) {
      if (e instanceof Error) {
        error.value = e.toString();
      } else {
        error.value = 'Ha ocurrido un error';
      }
    } finally {
      isLoading.value = false;
    }
  }

  return { result, isLoading, error, makeRequest };
}
