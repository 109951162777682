<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import type { Lead } from '@/types';

const props = defineProps<{
  lead: Lead | null;
  initialMessage: string;
}>();

const emit = defineEmits(['close']);

const message = ref('');

// Watch for changes in the initialMessage prop
watch(() => props.initialMessage, (newMessage) => {
  message.value = newMessage;
}, { immediate: true });

function formatPhoneNumber(countryCode: string, phoneNumber: string | null | undefined): string {
  if (!phoneNumber) return '-';
  const cleanCountryCode = countryCode.replace('+', '');
  if (phoneNumber.startsWith('+')) {
    return phoneNumber;
  }
  if (phoneNumber.startsWith(cleanCountryCode)) {
    return `+${phoneNumber}`;
  }
  if (cleanCountryCode === '52') {
    return `+52${phoneNumber.startsWith('1') ? '' : '1'}${phoneNumber}`;
  }

  return `+${cleanCountryCode}${phoneNumber}`;
}

const whatsappUrl = computed(() => {
  if (!props.lead) return '';
  const formattedPhone = formatPhoneNumber(props.lead.countryCode, props.lead.phoneNumber).replace(/\+/g, '');
  const encodedMessage = encodeURIComponent(message.value);

  return `https://wa.me/${formattedPhone}?text=${encodedMessage}`;
});

function openWhatsApp() {
  if (whatsappUrl.value) {
    window.open(whatsappUrl.value, '_blank');
  }
}
</script>

<template>
  <div
    v-if="lead"
    class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
  >
    <div class="w-full max-w-3xl rounded-lg bg-white p-8 shadow-xl">
      <h2 class="mb-4 text-2xl font-bold">
        Send Message to {{ lead.name }}
      </h2>
      <p class="mb-2 text-sm">
        Phone: {{ formatPhoneNumber(lead.countryCode, lead.phoneNumber) }}
      </p>
      <textarea
        v-model="message"
        class="mb-4 w-full rounded border p-4 text-base"
        rows="15"
      />
      <div class="flex justify-end space-x-4">
        <button
          class="rounded bg-gray-300 px-6 py-3 text-base text-gray-800"
          @click="$emit('close')"
        >
          Cancel
        </button>
        <button
          class="rounded bg-blue-500 px-6 py-3 text-base text-white"
          @click="openWhatsApp"
        >
          Open WhatsApp
        </button>
      </div>
    </div>
  </div>
</template>
